export enum ResourceTypes {
  coin1 = 'coin1',
  coin4 = 'coin4',
  coin8 = 'coin8',
  closenessLevelBase = 'closenessLevelBase',
  closenessLevelColor1 = 'closenessLevelColor1',
  closenessLevelColor2 = 'closenessLevelColor2',
  closenessLevelColor3 = 'closenessLevelColor3',
  closenessLevelColor4 = 'closenessLevelColor4',
  closenessLevelColor5 = 'closenessLevelColor5',
  freespinFrame = 'freespinFrame',
  iconHeart = 'iconHeart',
  infoHeartMeter = 'infoHeartMeter',
  reelBase = 'reelBase',
  reelframeBase = 'reelframeBase',
  symbolFNormalSp = 'symbolFNormalSp',
  symbolGNormalSp = 'symbolGNormalSp',
  symbolHNormalSp = 'symbolHNormalSp',
  symbolINormalSp = 'symbolINormalSp',
  symbolJNormalSp = 'symbolJNormalSp',
  titlelogo = 'titlelogo',
  symbolANormal = 'symbolANormal',
  symbolBNormal = 'symbolBNormal',
  symbolBNormalBlur = 'symbolBNormalBlur',
  symbolBSpecial = 'symbolBSpecial',
  symbolCNormal = 'symbolCNormal',
  symbolCNormalBlur = 'symbolCNormalBlur',
  symbolCSpecial = 'symbolCSpecial',
  symbolDNormal = 'symbolDNormal',
  symbolDNormalBlur = 'symbolDNormalBlur',
  symbolDSpecial = 'symbolDSpecial',
  symbolENormal = 'symbolENormal',
  symbolENormalBlur = 'symbolENormalBlur',
  symbolESpecial = 'symbolESpecial',
  symbolFNormal = 'symbolFNormal',
  symbolFNormalBlur = 'symbolFNormalBlur',
  symbolFSpecial = 'symbolFSpecial',
  symbolGNormal = 'symbolGNormal',
  symbolGNormalBlur = 'symbolGNormalBlur',
  symbolGSpecial = 'symbolGSpecial',
  symbolHNormal = 'symbolHNormal',
  symbolHNormalBlur = 'symbolHNormalBlur',
  symbolHSpecial = 'symbolHSpecial',
  symbolINormal = 'symbolINormal',
  symbolINormalBlur = 'symbolINormalBlur',
  symbolISpecial = 'symbolISpecial',
  symbolJNormal = 'symbolJNormal',
  symbolJNormalBlur = 'symbolJNormalBlur',
  symbolJSpecial = 'symbolJSpecial',
  symbolBWin1 = 'symbolBWin1',
  symbolBWin2 = 'symbolBWin2',
  symbolBWin3 = 'symbolBWin3',
  symbolCWin1 = 'symbolCWin1',
  symbolCWin2 = 'symbolCWin2',
  symbolCWin3 = 'symbolCWin3',
  symbolDWin1 = 'symbolDWin1',
  symbolDWin2 = 'symbolDWin2',
  symbolDWin3 = 'symbolDWin3',
  symbolEWin1 = 'symbolEWin1',
  symbolEWin2 = 'symbolEWin2',
  symbolEWin3 = 'symbolEWin3',
  symbolFWin1 = 'symbolFWin1',
  symbolFWin2 = 'symbolFWin2',
  symbolFWin3 = 'symbolFWin3',
  symbolGWin1 = 'symbolGWin1',
  symbolGWin2 = 'symbolGWin2',
  symbolGWin3 = 'symbolGWin3',
  symbolHWin1 = 'symbolHWin1',
  symbolHWin2 = 'symbolHWin2',
  symbolHWin3 = 'symbolHWin3',
  symbolIWin1 = 'symbolIWin1',
  symbolIWin2 = 'symbolIWin2',
  symbolIWin3 = 'symbolIWin3',
  symbolJWin1 = 'symbolJWin1',
  symbolJWin2 = 'symbolJWin2',
  symbolJWin3 = 'symbolJWin3',
  albumBtnHover = 'albumBtnHover',
  albumBtnNotActive = 'albumBtnNotActive',
  albumBtnPressed = 'albumBtnPressed',
  albumBtn = 'albumBtn',
  backdrop = 'backdrop',
  backgroundBaseEvening = 'backgroundBaseEvening',
  backgroundBaseNoon = 'backgroundBaseNoon',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnNotActive = 'buyFeatureBtnNotActive',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtnDisable = 'buyFeatureCancelBtnDisable',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnDisable = 'buyFeatureOkBtnDisable',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  intro1 = 'intro1',
  intro2 = 'intro2',
  intro3 = 'intro3',
  logo = 'logo',
  symbolLotteryWindow = 'symbolLotteryWindow',
  textLogo = 'textLogo',
}
