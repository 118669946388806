import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EventTypes, IAuthInput } from '../../global.d';
import {
  setCurrentBonus,
  setGameMode,
  setIsTimeoutErrorMessage,
  setProgress,
  setReplayBet,
  setReplayFreeSpinBets,
  setReplayFreeSpinPlayedRounds,
  setReplayFreeSpinReelSetId,
  setSkipIntroScreen,
  setSlotConfig,
  setStressful,
} from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { isFreeSpinsMode, parseQuery, queryParams } from '../../utils';
import GameScreen from '../GameScreen';
import LoadScreen from '../LoadScreen';

const App: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const progress = useReactiveVar(setProgress);
  const { i18n } = useTranslation();

  const handleIdle = useCallback(
    (state: SlotMachineState) => {
      if (state !== SlotMachineState.IDLE) return;
      const replayFreeSpins = setReplayFreeSpinBets();
      if (!replayFreeSpins.length || setReplayFreeSpinPlayedRounds() === replayFreeSpins.length) {
        setIsTimeoutErrorMessage(true);
        setStressful({
          show: true,
          type: 'none',
          message: i18n.t('replayBetMessage'),
        });
      }
    },
    [i18n],
  );

  useEffect(() => {
    if (queryParams.has('replayBetId')) {
      setSkipIntroScreen(true);
      setReplayBet(queryParams.get('replayBetId') || '');
    }
    eventManager.on(EventTypes.POST_RENDER, () => {
      if (setReplayBet()) {
        window.setTimeout(() => {
          eventManager.emit(EventTypes.SET_REPLAY_BET_ID);
          eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);

          if (setReplayFreeSpinReelSetId()) {
            if (isFreeSpinsMode(setGameMode())) {
              eventManager.emit(EventTypes.CHANGE_REEL_SET, {
                reelSet: setSlotConfig().reels.find((reel) => reel.id === setReplayFreeSpinReelSetId())!,
                reelPositions: [0, 0, 0, 0, 0],
              });
            }
          }

          if (!setCurrentBonus().isActive) {
            eventManager.emit(EventTypes.TOGGLE_SPIN);
          }
          eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
            handleIdle(state);
          });
        }, 0);
      }
    });
  }, [handleIdle]);

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);

  return (
    <>
      <LoadScreen />
      {data?.isAuthorized && progress?.status >= 100 && <GameScreen />}
    </>
  );
};

export default App;
