// 予兆振り分け
export const baseGamePhoenix = [20, 100];

// Animation selection table
export enum AnimationType {
  NON = 'No anticipation',
  PHOENIX = 'phoenix',
}

// [7.AvatarMotion]

// A. ベースゲーム中の待機ループ [待機1～待機5] 5
export const aBaseGameWaitLoop1 = [[20, 40, 60, 80, 100]];

// B. フィーチャ当選時 リアクション6  2,3,5  不使用1,4,6
export const bBonusWinReaction = [[0, 34, 67, 67, 100, 100]];

// C. Win当せん時（Big未満） リアクション 1,2,4,6 不使用 3,5
export const cWinReaction = [[25, 50, 50, 75, 75, 100]];

// D. Big Win以上当せん時 リアクション 3,5 不使用 1,2,4,6
export const dBigWinReaction = [[0, 0, 50, 0, 100, 100]];

// E. watchingループ 3　ウオッチング3
export const eFreeSpinGameWaitLoop = [[34, 67, 100]];

// F. Big以上のエキスパンドWin リアクション 7,8 不使用 1,2,3,4,5,6
export const eFreeSpinGameExpand = [[0, 0, 0, 0, 0, 0, 50, 100]];

// G. Total win看板(Betの10倍未満) リアクション 1,2, 不使用 3,4,5,6
export const gTotalWinUnderReaction = [[50, 100, 100, 100, 100, 100, 100, 100]];

// H. Total win看板(Betの10倍以上) リアクション 7,8, 不使用 1,2,3,4,5,6
export const hTotalWinOverReaction = [[0, 0, 0, 0, 0, 0, 50, 100]];

// H. Total win看板(Betの10倍以上) リアクション 7,8, 不使用 1,2,3,4,5,6
export const hLotSelect = [[50, 100]];
