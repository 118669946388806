import React, { useEffect, useState } from 'react';

import type { PropsInput, TypeInput } from './d';
import styles from './input.module.scss';

export const onChangeInput =
  (fn: (v: string | number) => void, type: TypeInput) =>
  (event: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = event;
    if (type === 'number') {
      fn(+value);
    } else {
      fn(value);
    }
  };

// eslint-disable-next-line react/display-name
const Input = React.forwardRef<HTMLInputElement, PropsInput>((props, ref) => {
  const {
    type = 'text',
    value = '',
    onChange,
    showPassword = false,
    className = '',
    readOnly = false,
    ...otherProps
  } = props;
  const [typeInput, changeTypeInput] = useState(type);

  useEffect(() => {
    if (type === 'password' && showPassword) {
      changeTypeInput('text');
    }

    if (type === 'password' && !showPassword) {
      changeTypeInput('password');
    }
  }, [showPassword, type]);

  return (
    <input
      className={`${styles['input']} ${className}`}
      type={typeInput}
      value={value}
      onChange={onChangeInput(onChange, type)}
      readOnly={readOnly}
      ref={ref}
      {...otherProps}
    />
  );
});

export default Input;
