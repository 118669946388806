import { IAddOptions, LoaderResource } from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.WL]: ResourceTypes.symbolANormal,
  [SlotId.B]: ResourceTypes.symbolBNormal,
  [SlotId.C]: ResourceTypes.symbolCNormal,
  [SlotId.D]: ResourceTypes.symbolDNormal,
  [SlotId.E]: ResourceTypes.symbolENormal,
  [SlotId.F]: ResourceTypes.symbolFNormal,
  [SlotId.G]: ResourceTypes.symbolGNormal,
  [SlotId.H]: ResourceTypes.symbolHNormal,
  [SlotId.I]: ResourceTypes.symbolINormal,
  [SlotId.J]: ResourceTypes.symbolJNormal,
};
export const MAPPED_BLURRED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.WL]: ResourceTypes.symbolANormal,
  [SlotId.B]: ResourceTypes.symbolBNormalBlur,
  [SlotId.C]: ResourceTypes.symbolCNormalBlur,
  [SlotId.D]: ResourceTypes.symbolDNormalBlur,
  [SlotId.E]: ResourceTypes.symbolENormalBlur,
  [SlotId.F]: ResourceTypes.symbolFNormalBlur,
  [SlotId.G]: ResourceTypes.symbolGNormalBlur,
  [SlotId.H]: ResourceTypes.symbolHNormalBlur,
  [SlotId.I]: ResourceTypes.symbolINormalBlur,
  [SlotId.J]: ResourceTypes.symbolJNormalBlur,
};

export const MAPPED_SP_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.WL]: ResourceTypes.symbolANormal,
  [SlotId.B]: ResourceTypes.symbolBNormal,
  [SlotId.C]: ResourceTypes.symbolCNormal,
  [SlotId.D]: ResourceTypes.symbolDNormal,
  [SlotId.E]: ResourceTypes.symbolENormal,
  [SlotId.F]: ResourceTypes.symbolFNormalSp,
  [SlotId.G]: ResourceTypes.symbolGNormalSp,
  [SlotId.H]: ResourceTypes.symbolHNormalSp,
  [SlotId.I]: ResourceTypes.symbolINormalSp,
  [SlotId.J]: ResourceTypes.symbolJNormalSp,
};

export const MAPPED_SYMBOLS_STOP_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_a_announce',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_b_announce_sp',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_c_announce_sp',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_d_announce_sp',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_e_announce_sp',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_f_announce_sp',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_g_announce_sp',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_h_announce_sp',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_i_announce_sp',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_j_announce_sp',
  },
};

export type StopAnimationRen = {
  type: SymbolAnimationType;
  src?: string;
  animation?: string;
};

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
    animationSp?: string;
  };
} = {
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_a_win',
    animationSp: 'symbol_a_win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_b_win',
    animationSp: 'symbol_b3_win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_c_win',
    animationSp: 'symbol_c3_win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_d_win',
    animationSp: 'symbol_d3_win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_e_win',
    animationSp: 'symbol_e3_win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_f_win',
    animationSp: 'symbol_f3_win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_g_win',
    animationSp: 'symbol_g3_win',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_h_win',
    animationSp: 'symbol_h3_win',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_i_win',
    animationSp: 'symbol_i3_win',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_j_win',
    animationSp: 'symbol_j3_win',
  },
};

export const LOADER_TEXTURES: IAddOptions[] = [
  ResourceTypes.closenessLevelBase,
  ResourceTypes.freespinFrame,
  ResourceTypes.backgroundBaseNoon,
  ResourceTypes.backgroundBaseEvening,
  ResourceTypes.intro1,
  ResourceTypes.intro2,
  ResourceTypes.intro3,
  ResourceTypes.buttonOk,
  ResourceTypes.backdrop,
  ResourceTypes.logo,
  ResourceTypes.symbolANormal,
  ResourceTypes.symbolBNormal,
  ResourceTypes.symbolCNormal,
  ResourceTypes.symbolDNormal,
  ResourceTypes.symbolENormal,
  ResourceTypes.symbolFNormal,
  ResourceTypes.symbolGNormal,
  ResourceTypes.symbolHNormal,
  ResourceTypes.symbolINormal,
  ResourceTypes.symbolJNormal,
  ResourceTypes.symbolBNormalBlur,
  ResourceTypes.symbolCNormalBlur,
  ResourceTypes.symbolDNormalBlur,
  ResourceTypes.symbolENormalBlur,
  ResourceTypes.symbolFNormalBlur,
  ResourceTypes.symbolGNormalBlur,
  ResourceTypes.symbolHNormalBlur,
  ResourceTypes.symbolINormalBlur,
  ResourceTypes.symbolJNormalBlur,
  ResourceTypes.symbolBSpecial,
  ResourceTypes.symbolCSpecial,
  ResourceTypes.symbolDSpecial,
  ResourceTypes.symbolESpecial,
  ResourceTypes.symbolFSpecial,
  ResourceTypes.symbolGSpecial,
  ResourceTypes.symbolHSpecial,
  ResourceTypes.symbolISpecial,
  ResourceTypes.symbolJSpecial,
  ResourceTypes.reelBase,
  ResourceTypes.reelframeBase,
  ResourceTypes.titlelogo,
  ResourceTypes.buyFeatureBtn,
  ResourceTypes.buyFeatureBtnHover,
  ResourceTypes.buyFeatureBtnNotActive,
  ResourceTypes.buyFeatureBtnPressed,
  ResourceTypes.buyFeatureCancelBtn,
  ResourceTypes.buyFeatureCancelBtnDisable,
  ResourceTypes.buyFeatureCancelBtnHover,
  ResourceTypes.buyFeatureCancelBtnPressed,
  ResourceTypes.buyFeatureInput,
  ResourceTypes.buyFeatureMinusBtn,
  ResourceTypes.buyFeatureMinusBtnDisable,
  ResourceTypes.buyFeatureMinusBtnHover,
  ResourceTypes.buyFeatureMinusBtnPressed,
  ResourceTypes.buyFeaturePlusBtn,
  ResourceTypes.buyFeaturePlusBtnDisable,
  ResourceTypes.buyFeaturePlusBtnHover,
  ResourceTypes.buyFeaturePlusBtnPressed,
  ResourceTypes.buyFeatureOkBtn,
  ResourceTypes.buyFeatureOkBtnDisable,
  ResourceTypes.buyFeatureOkBtnHover,
  ResourceTypes.buyFeatureOkBtnPressed,
  ResourceTypes.buyFeaturePopup,
  ResourceTypes.buyFeaturePopupConfirm,
  ResourceTypes.albumBtn,
  ResourceTypes.albumBtnHover,
  ResourceTypes.albumBtnNotActive,
  ResourceTypes.albumBtnPressed,
  ResourceTypes.infoHeartMeter,
  ResourceTypes.closenessLevelColor1,
  ResourceTypes.closenessLevelColor2,
  ResourceTypes.closenessLevelColor3,
  ResourceTypes.closenessLevelColor4,
  ResourceTypes.closenessLevelColor5,
  ResourceTypes.symbolFNormalSp,
  ResourceTypes.symbolGNormalSp,
  ResourceTypes.symbolHNormalSp,
  ResourceTypes.symbolINormalSp,
  ResourceTypes.symbolJNormalSp,
  ResourceTypes.symbolBWin1,
  ResourceTypes.symbolBWin2,
  ResourceTypes.symbolBWin3,
  ResourceTypes.symbolCWin1,
  ResourceTypes.symbolCWin2,
  ResourceTypes.symbolCWin3,
  ResourceTypes.symbolDWin1,
  ResourceTypes.symbolDWin2,
  ResourceTypes.symbolDWin3,
  ResourceTypes.symbolEWin1,
  ResourceTypes.symbolEWin2,
  ResourceTypes.symbolEWin3,
  ResourceTypes.symbolFWin1,
  ResourceTypes.symbolFWin2,
  ResourceTypes.symbolFWin3,
  ResourceTypes.symbolGWin1,
  ResourceTypes.symbolGWin2,
  ResourceTypes.symbolGWin3,
  ResourceTypes.symbolHWin1,
  ResourceTypes.symbolHWin2,
  ResourceTypes.symbolHWin3,
  ResourceTypes.symbolIWin1,
  ResourceTypes.symbolIWin2,
  ResourceTypes.symbolIWin3,
  ResourceTypes.symbolJWin1,
  ResourceTypes.symbolJWin2,
  ResourceTypes.symbolJWin3,
  ResourceTypes.symbolLotteryWindow,
  ResourceTypes.coin1,
  ResourceTypes.coin4,
  ResourceTypes.coin8,
].map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const SPINE_ATLASES: IAddOptions[] = [
  {
    name: 'spine_atlas',
    url: '/animations/desktop/symbol/spine.atlas',
    loadType: LoaderResource.LOAD_TYPE.XHR,
    xhrType: LoaderResource.XHR_RESPONSE_TYPE.TEXT,
  },
];

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};
export const SPINE_LOADER_TEXTURES = (isMobile: boolean): IAddOptions[] => {
  return [
    {
      name: 'symbol_all',
      url: generateTexturePath('/animations', 'symbol_all/symbol_all.json', isMobile),
    },
    {
      name: 'coinsAnimation',
      url: generateTexturePath('/animations', 'coins/coins.json'),
    },
    {
      name: 'freespin_meter',
      url: generateTexturePath('/animations', 'freespin_meter/freespin_mater.json'),
    },
    {
      name: 'heart_acquisition',
      url: generateTexturePath('/animations', 'heart_acquisition/heart_acquisition.json'),
    },
    {
      name: 'heart_stock',
      url: generateTexturePath('/animations', 'heart_stock/heart_stock.json'),
    },
    {
      name: 'symbol_expand',
      url: generateTexturePath('/animations', 'symbol_expand/symbol_expand.json'),
    },
    {
      name: 'symbol_lottery',
      url: generateTexturePath('/animations', 'symbol_lottery/symbol_lottery.json'),
    },
    {
      name: 'totalwin_flash',
      url: generateTexturePath('/animations', 'totalwin_flash/totalwin_flash.json'),
    },
    {
      name: 'freespin_ui_flash',
      url: generateTexturePath('/animations', 'freespin_ui_flash/freespin_ui_flash.json'),
    },
    {
      name: 'avatar_Mr.First',
      url: generateTexturePath('/animations', 'avatar_Mr.First/avatar_Mr.First.json'),
    },
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'buttonsSprite',
    url: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    url: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    url: '/images/loader/loader.json',
  },
  {
    name: 'phoenixMovie',
    url: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
];
