import i18n from 'i18next';
import { Container, Graphics, Text } from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { EventTypes } from '../../global.d';
import {
  setBottomContainerTotalWin,
  setCurrency,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setIsOpenedMessageBanner,
} from '../../gql/cache';
import { normalizeCoins, showCurrency } from '../../utils';
import { eventManager } from '../config';

import { LAYOUT_OPTIONS, buttonTextStyle, freeRoundsTextStyles } from './config';

export class FreeRoundsEndPopup extends Container {
  protected popup: Container;

  protected freeRounds: Text;

  protected freeRoundsLabel: Text;

  protected freeRoundsAmount: Text;

  private bindedCallback = () => {
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    this.visible = false;

    eventManager.emit(EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS, {
      inDuration: 2000,
      outDuration: 1000,
      callback: () => {
        setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
        setIsOpenedMessageBanner(false);
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({
            currency: setCurrency(),
            value: normalizeCoins(setBottomContainerTotalWin()),
            showCurrency: showCurrency(setCurrency()),
          }),
        );
        setBottomContainerTotalWin(0);
        setFreeRoundsTotalWin(0);
        eventManager.emit(EventTypes.SET_IS_FREE_ROUND_AMOUNT_VISIBLE, false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      },
    });

    eventManager.emit(EventTypes.POPUP_BG_VISIBLE, false);
  };

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.freeRounds = this.initFreeRoundsText();
    this.freeRoundsAmount = this.initFreeRoundsAmount();
    this.freeRoundsLabel = this.initFreeRoundsLabel();
    this.popup = this.initPopup();
    this.init();

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.END_FREE_ROUND_BONUS, this.show.bind(this));
  }

  private init(): void {
    this.addChild(this.popup);
  }

  private initPopup(): Container {
    const container = new Container();
    const innerBg = new Graphics()
      .beginFill(LAYOUT_OPTIONS.bgColor)
      .drawRoundedRect(0, 0, LAYOUT_OPTIONS.width, LAYOUT_OPTIONS.height, LAYOUT_OPTIONS.borderRadius)
      .endFill();
    innerBg.position.set(LAYOUT_OPTIONS.border);
    const outerBg = new Graphics()
      .beginFill(LAYOUT_OPTIONS.borderColor)
      .drawRoundedRect(
        0,
        0,
        LAYOUT_OPTIONS.width + 2 * LAYOUT_OPTIONS.border,
        LAYOUT_OPTIONS.height + 2 * LAYOUT_OPTIONS.border,
        LAYOUT_OPTIONS.borderRadius,
      )
      .endFill();
    const closeBtn = this.createButton();
    closeBtn.position.set(
      LAYOUT_OPTIONS.width / 2 - closeBtn.width / 2,
      LAYOUT_OPTIONS.height - (closeBtn.height * 3) / 2,
    );

    container.addChild(outerBg, innerBg, closeBtn, this.freeRounds, this.freeRoundsAmount, this.freeRoundsLabel);

    return container;
  }

  private initFreeRoundsLabel(): Text {
    const text = new Text(i18n.t<string>('freeRoundsCompleted'), freeRoundsTextStyles);
    text.anchor.set(0.5, 0.5);
    text.position.set(215, 57);
    return text;
  }

  private initFreeRoundsAmount(): Text {
    const text = new Text('', { ...freeRoundsTextStyles, fontSize: 50 });
    text.anchor.set(0.5, 0.5);
    text.position.set(215, 115);
    return text;
  }

  private initFreeRoundsText(): Text {
    const text = new Text(i18n.t<string>('returnToTheGame'), freeRoundsTextStyles);
    text.anchor.set(0.5, 0.5);
    text.position.set(215, 175);
    return text;
  }

  private resize(width: number, height: number): void {
    if (width - 50 < LAYOUT_OPTIONS.width) {
      this.popup.scale.set(width / (LAYOUT_OPTIONS.width + 50));
    } else if (height - 100 < LAYOUT_OPTIONS.height) {
      this.popup.scale.set(height / (LAYOUT_OPTIONS.height + 100));
    } else {
      this.popup.scale.set(1);
    }
    this.popup.position.set(width / 2 - this.popup.width / 2, height / 2 - this.popup.height / 2);
  }

  private show(): void {
    setIsOpenedMessageBanner(true);
    eventManager.emit(EventTypes.POPUP_BG_VISIBLE, true);
    this.freeRoundsAmount.visible = true;
    this.freeRoundsLabel.y = 57;
    this.freeRounds.y = 175;
    if (setBottomContainerTotalWin() > 0) {
      this.freeRoundsAmount.text = `${formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(setBottomContainerTotalWin()),
        showCurrency: showCurrency(setCurrency()),
      })}`;
    } else {
      this.freeRoundsLabel.y = 89;
      this.freeRounds.y = 155;
      this.freeRoundsAmount.visible = false;
    }
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    this.visible = true;
  }

  private createButton(): Container {
    const width = LAYOUT_OPTIONS.width / 3;
    const height = LAYOUT_OPTIONS.width / 8;
    const borderWidth = 3;
    const { borderColor, buttonColor, borderRadius } = LAYOUT_OPTIONS;
    const radius = borderRadius;

    const button = new Graphics();

    button
      .lineStyle(borderWidth, borderColor)
      .moveTo(width - radius, 0)
      .arcTo(width, 0, width, radius, radius)
      .lineTo(width, height - radius)
      .arcTo(width, height, width - radius, height, radius)
      .lineTo(radius, height)
      .arcTo(0, height, 0, height - radius, radius);

    button.beginFill(buttonColor).drawRoundedRect(0, 0, width, height, radius).endFill();

    const text = new Text(i18n.t<string>('close'), buttonTextStyle);
    text.anchor.set(0.5);
    text.position.set(width / 2, height / 2);

    button.interactive = true;
    button.cursor = 'pointer';
    button.on('pointertap', () => this.bindedCallback());
    button.addChild(text);

    return button;
  }
}
