import * as PIXI from 'pixi.js';

import SlotMachine from '..';
import { MAPPED_SP_SYMBOLS, SlotId } from '../../config';
import { EventTypes, bonusIdsStringToGameMode } from '../../global.d';
import {
  setBrokenGame,
  setCurrentBonus,
  setIsContinueAutoSpinsAfterFeature,
  setIsOpenedMessageBanner,
  setNextResult,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils/utils';
import Tween from '../animations/tween';
import {
  SAFE_AREA_LANDSCAPE_WIDTH,
  Z_INDEX_AVATAR,
  eventManager,
  getSpSymbolGameModeToSlotId,
  getSpSymbolList,
} from '../config';

import {
  BOOK_ANIMATION_POS_X,
  BOOK_ANIMATION_POS_Y,
  FreeSpinDescTextStyles,
  FreeSpinNumTextStyles,
  FreeSpinTitleTextStyles,
  PressStartTextStyles,
  SP_SYMBOL_PRESS_START_DELAY,
  SP_SYMBOL_TEXT_MARGIN,
} from './config';

class SpSymbolLotText extends PIXI.Container {
  private spSymbol: PIXI.Sprite;

  private pressStartText: PIXI.Text;

  private freeSpinsNum: PIXI.Text;

  private freeSpinsText: PIXI.Text;

  private freeSpinsDesc1: PIXI.Text;

  private spSymbol_lottery_window: PIXI.Sprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolLotteryWindow));

  constructor() {
    super();

    this.visible = false;
    this.position.set(BOOK_ANIMATION_POS_X, BOOK_ANIMATION_POS_Y);
    this.sortableChildren = true;
    this.spSymbol = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolBNormal));
    this.freeSpinsNum = new PIXI.Text(i18n.t<string>('spSymbolLot.freeSpinNum'), FreeSpinNumTextStyles);
    this.freeSpinsText = new PIXI.Text(i18n.t<string>('spSymbolLot.freeSpins'), FreeSpinTitleTextStyles);
    this.freeSpinsDesc1 = new PIXI.Text(i18n.t<string>('spSymbolLot.spSymbolDescFix'), FreeSpinDescTextStyles);
    this.initBookText();
    this.pressStartText = this.initText();
    this.addChild(this.pressStartText);
    this.pivot.set(0.5);
    this.zIndex = Z_INDEX_AVATAR;

    eventManager.addListener(EventTypes.PRESS_START_VISIBLE, this.startTextVisible.bind(this));
    eventManager.addListener(EventTypes.SP_SYMBOL_LOT_START, this.spSymbolLotStart.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.BOOK_INFO_TEXT_VISIBLE, this.bookInfoTextVisible.bind(this));
    eventManager.addListener(EventTypes.SP_SYMBOL_IF_VISIBLE, this.spSymbolIfVisible.bind(this));
  }

  private initText(): PIXI.Text {
    const pressStart = new PIXI.Text(i18n.t<string>('startText'), PressStartTextStyles);
    pressStart.resolution = 1;
    pressStart.anchor.set(0.5, 0.5);
    updateTextScale(pressStart, SAFE_AREA_LANDSCAPE_WIDTH - 100, 150);
    return pressStart;
  }

  private initBookText(): void {
    this.spSymbol_lottery_window.pivot.set(0.5);
    this.spSymbol_lottery_window.position.set(-this.spSymbol_lottery_window.width / 2, 300);
    this.spSymbol_lottery_window.visible = false;

    this.freeSpinsNum.resolution = 1;
    this.freeSpinsText.resolution = 1;
    this.freeSpinsDesc1.resolution = 1;

    this.freeSpinsNum.anchor.set(0.5);
    this.freeSpinsText.anchor.set(0.5);
    this.freeSpinsDesc1.anchor.set(0.5);
    this.freeSpinsText.position.set(410, 50);
    this.freeSpinsNum.position.set(410, -40);
    this.freeSpinsDesc1.position.set(
      0,
      this.spSymbol_lottery_window.y + (this.spSymbol_lottery_window.height * this.spSymbol_lottery_window.scale.y) / 2,
    );

    this.freeSpinsNum.visible = false;
    this.freeSpinsText.visible = false;
    this.freeSpinsDesc1.visible = false;
    this.addChild(this.spSymbol_lottery_window, this.freeSpinsNum, this.freeSpinsText, this.freeSpinsDesc1);
  }

  private startTextVisible(visible: boolean): void {
    this.pressStartText.visible = visible;
  }

  private bookInfoTextVisible(visible: boolean): void {
    this.freeSpinsNum.visible = visible;
    this.freeSpinsText.visible = visible;
    this.freeSpinsDesc1.visible = visible;
    this.spSymbol_lottery_window.visible = visible;
  }

  private spSymbolLotStart(): void {
    setIsOpenedMessageBanner(true);
    this.visible = true;
    this.pressStartText.visible = true;
    this.freeSpinsNum.visible = false;
    this.freeSpinsText.visible = false;
    this.freeSpinsDesc1.visible = false;
    this.spSymbol_lottery_window.visible = false;

    let slot: SlotId;
    if (setBrokenGame()) {
      slot = getSpSymbolGameModeToSlotId[bonusIdsStringToGameMode[setCurrentBonus().bonusId]!]!;
    } else {
      slot = getSpSymbolList[setNextResult()?.bet.data.features.gameRoundStore.id!]!;
    }

    this.spSymbol.texture = PIXI.Texture.from(MAPPED_SP_SYMBOLS[slot]);

    if (setIsContinueAutoSpinsAfterFeature()) {
      const delay = Tween.createDelayAnimation(SP_SYMBOL_PRESS_START_DELAY);
      delay.addOnComplete(() => {
        eventManager.emit(EventTypes.SPACE_KEY_CLOSE_SP_SYMBOL_LOT);
      });
      delay.start();
    }

    switch (slot) {
      case SlotId.E:
      case SlotId.I:
        this.freeSpinsDesc1.text = i18n.t<string>('spSymbolLot.spSymbolDescNon');
        break;
      case SlotId.C:
      case SlotId.F:
        this.freeSpinsDesc1.text = i18n.t<string>('spSymbolLot.spSymbolDescFix');
        break;
      case SlotId.B:
      case SlotId.D:
      case SlotId.G:
      case SlotId.H:
      case SlotId.J:
        this.freeSpinsDesc1.text = i18n.t<string>('spSymbolLot.spSymbolDescUp');
        break;
    }

    if (this.freeSpinsDesc1.width >= this.spSymbol_lottery_window.width - SP_SYMBOL_TEXT_MARGIN) {
      this.freeSpinsDesc1.width = this.spSymbol_lottery_window.width - SP_SYMBOL_TEXT_MARGIN;
    }

    this.freeSpinsNum.text = i18n.t<string>('spSymbolLot.freeSpinNum', {
      spin: setCurrentBonus().rounds,
    });
    this.freeSpinsText.text = i18n.t<string>('spSymbolLot.freeSpins');
  }

  private spSymbolIfVisible(): void {
    if (!this.visible) return;

    this.visible = false;

    this.freeSpinsNum.visible = false;
    this.freeSpinsText.visible = false;
    this.freeSpinsDesc1.visible = false;
    this.spSymbol_lottery_window.visible = false;
  }

  private resize(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    _scale: number,
    _pivotX: number,
    _pivotY: number,
  ): void {
    updateTextScale(this.pressStartText, SlotMachine.getInstance().background.width - 100, 150);
  }
}

export default SpSymbolLotText;
