import { useQuery } from '@apollo/client';
import * as _ from 'lodash';
import React from 'react';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, IUserBalance } from '../../global.d';
import { setBetAmount, setCoinAmount, setCoinValue, setSlotConfig } from '../../gql/cache';
import { getBetsSetting } from '../../gql/fromFragment';
import { getBetAmountGql, getUserGql } from '../../gql/query';
import { eventManager } from '../../slotMachine/config';
import { countCoins, formatNumber, normalizeCoins, showCurrency, wrap } from '../../utils';
import Button from '../conponentsBfth/button/index';
import Input from '../conponentsBfth/input/index';
import RangeSlider from '../conponentsBfth/rangeSlider/index';

import styles from './betSettings.module.scss';
import { IBetSettingsProps } from './d';

const BetSettings: React.FC<IBetSettingsProps> = (props) => {
  const { className = '' } = props;
  const { data, client } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const betSettings = getBetsSetting();
  const value = data?.betAmount ?? betSettings.minBet;
  const coinAmountMultiplier = setSlotConfig().lineSet.coinAmountMultiplier;
  const {
    user: { balance },
  } = client.readQuery<{ user: IUserBalance }>({
    query: getUserGql,
  })!;

  const changeBet = (v: number) => {
    if (betSettings.maxBet === v) {
      AudioApi.play({ type: ISongs.SFX_UI_MaxBet });
    } else {
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }

    setCoinAmount(v);
    setBetAmount(v * coinAmountMultiplier);
    eventManager.emit(EventTypes.UPDATE_BET);
    eventManager.emit(EventTypes.TOGGLE_UI_MENU);
  };

  const changeSliderBet = (v: number) => {
    if (betSettings.maxBet === betSettings.bets[v - 1]) {
      AudioApi.play({ type: ISongs.SFX_UI_MaxBet });
    } else {
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }

    setCoinAmount(betSettings.bets[v - 1]);
    setBetAmount(coinAmountMultiplier * (betSettings.bets[v - 1] || 0));
    eventManager.emit(EventTypes.UPDATE_BET);
    eventManager.emit(EventTypes.TOGGLE_UI_MENU);
  };

  return (
    <div className={`${styles['bet-settings']} ${className}`}>
      <Input
        className={`input ${styles['input']}`}
        type="text"
        value={formatNumber(
          balance.currency,
          normalizeCoins(countCoins({ totalAmount: data!.betAmount })),
          showCurrency(balance.currency),
        )}
        onChange={() => {}}
        readOnly
      />
      <RangeSlider
        className={`range-slider ${styles['range-slider']}`}
        min={1}
        max={betSettings.bets.length}
        value={_.findIndex(betSettings.bets, (bet) => bet === value / coinAmountMultiplier) + 1}
        onChange={changeSliderBet}
      />
      <div className={styles['fast-bet']}>
        {betSettings.quick.map((item) => (
          <div key={item} className={styles['fast-bet__item']}>
            <Button
              intent="betSettingsBtn"
              className={styles['btn']!}
              isActive={value === item * coinAmountMultiplier}
              title={formatNumber(
                balance.currency,
                countCoins({
                  coinAmount: item,
                  coinValue: setCoinValue(),
                  lines: coinAmountMultiplier,
                }),
                true,
              )}
              onClick={wrap(changeBet, item)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BetSettings;
