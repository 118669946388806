import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinsMode } from '../../utils/helper';
import ViewContainer from '../components/container';
import {
  GAME_TITLE_LOGO_X_POSITION,
  GAME_TITLE_LOGO_Y_POSITION,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';

class GameTitle extends ViewContainer {
  private titleLogo = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.titlelogo));

  constructor() {
    super();

    this.initGameLogo();

    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
  }

  private initGameLogo(): void {
    this.titleLogo.anchor.set(0.5);
    this.titleLogo.x = SLOTS_CONTAINER_WIDTH / 2 + GAME_TITLE_LOGO_X_POSITION;
    this.titleLogo.y = SLOTS_CONTAINER_HEIGHT / 2 + GAME_TITLE_LOGO_Y_POSITION;
    this.addChild(this.titleLogo);
  }

  private onModeChange(settings: { mode: GameMode }) {
    if (isFreeSpinsMode(settings.mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }
}

export default GameTitle;
