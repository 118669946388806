import { BetBonusReward, BetReward, BonusStatus, ISettledBet, UserBonus, freeRoundBonusId } from '../../global.d';
import { setSlotConfig } from '../../gql/cache';
import client from '../../gql/client';
import { getUserBonuses } from '../../gql/query';

export const isFreeRoundBonus = (bonusId: string): boolean => {
  return bonusId === freeRoundBonusId;
};

export const getFreeRoundBonus = (nextResult: ISettledBet): UserBonus | undefined => {
  const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';
  return nextResult?.rewards.filter(isBonusReward).find((reward) => {
    return reward.userBonus?.bonus.id === freeRoundBonusId;
  })?.userBonus;
};

export const getUserActiveBonuses = async () => {
  return client.query<{ userBonuses: UserBonus[] }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });
};
