import { ITextStyle } from 'pixi.js';

import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';

const font = 'NotoSans-SemiCondensedBold';

export const SP_SYMBOL_PRESS_START_DELAY = 3000;
export const SP_SYMBOL_DISPLAY_WAIT = 3000;

export const SP_SYMBOL_LOT_SPINE_BASE_WIDTH = 1400;
export const SP_SYMBOL_LOT_SPINE_BASE_HEIGHT = 900;

export const SP_SYMBOL_LOT_BASE_ALPHA = 0.5;
export const SP_SYMBOL_LOT_BASE_FADE_OUT_TIME = 3000;

export const DISPLAY_SHOW_DELAY = 300;

export const SP_SYMBOL_TEXT_MARGIN = 150;

export const ANIMATIONS_STEPS = ['lottery', 'idle_ex', 'lottery_set'];
export const ANIMATIONS = {
  lottery: 0,
  idle_ex: 1,
  lottery_set: 2,
};

export const BOOK_ANIMATION_POS_X = SLOTS_CONTAINER_WIDTH / 2;
export const BOOK_ANIMATION_POS_Y = SLOTS_CONTAINER_HEIGHT / 2 - 50;

export const PressStartTextStyles: Partial<ITextStyle> = {
  fontSize: 80,
  fontFamily: font,
  whiteSpace: 'normal',
  align: 'center',
  fill: ['#11C41C', '#11FF3C', '#11FFFF', '#118737', '#11FF11', '#11C41C', '#118418', '#116518', '#11eb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#ffffff',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 6,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const FreeSpinNumTextStyles: Partial<ITextStyle> = {
  fontSize: 100,
  fontFamily: font,
  whiteSpace: 'normal',
  align: 'right',
  fill: ['#fbc41c', '#f5ff3c', '#ffffff', '#ff8737', '#f9ff11', '#fbc41c', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#ffffff',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const FreeSpinTitleTextStyles: Partial<ITextStyle> = {
  fontSize: 60,
  fontFamily: font,
  whiteSpace: 'normal',
  align: 'right',
  fill: ['#fbc41c', '#f5ff3c', '#ffffff', '#ff8737', '#f9ff11', '#fbc41c', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#ffffff',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const FreeSpinDescTextStyles: Partial<ITextStyle> = {
  fontSize: 40,
  fontFamily: font,
  whiteSpace: 'normal',
  align: 'center',
  fill: ['#ff0000', '#ff3728', '#700050'],
  fillGradientType: 0,
  stroke: '#ffffff',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fontWeight: 'bolder',
  lineJoin: 'round',
};
