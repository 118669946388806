import React from 'react';

import styles from './titleName.module.scss';

const TitleName: React.FC = () => {
  // const [time, setTime] = useState('');

  // const updateTime = () => {
  //   const date = new Date();
  //   const hours = date.getHours();
  //   const minutes = date.getMinutes();
  //   const nHours = hours < 10 ? `0${hours}` : hours;
  //   const nMinutes = minutes < 10 ? `0${minutes}` : minutes;
  //   setTime(`${nHours}:${nMinutes}`);
  // };

  // useEffect(() => {
  //   const timerId = setInterval(updateTime, 60000);
  //   updateTime();
  //   return () => {
  //     clearInterval(timerId);
  //   };
  // }, []);

  return <div className={styles['titleName']}>{'MR.FIRST BOOK OF WAIFU'}</div>;
};

export default TitleName;
