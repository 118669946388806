import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { Popup } from '@phoenix7dev/shared-components';

import { ISongs, WinCharacterImg, config, winCharacterTbl } from '../../config';
import { EventTypes, ModalOpeningTypes } from '../../global.d';
import {
  setAlbumListPosition,
  setIsModalOpeningInProgress,
  setIsOpenAlbumInfo,
  setIsOpenAlbumModal,
} from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import SlotMachine from '../../slotMachine';
import { eventManager } from '../../slotMachine/config';
import { isMobileDevice } from '../../utils';
import Resources from '../../utils/resources';
import Button from '../conponentsBfth/button/index';

import styles from './info.module.scss';

const CharaTable: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [wimCharacterNumber, setWimCharacterNumber] = useState('');
  const [wimCharacterImgNumber, setWimCharacterImgNumber] = useState(WinCharacterImg.img1);
  const albumIcon = config.albumChara;

  const { data: configData } = useQuery<IConfig>(configGql);
  const { isOpenAlbumInfo } = configData!;

  const handleImgClick = (slug: string, index: number): void => {
    const divElement = document.getElementById('album-list-id');
    setAlbumListPosition(divElement!.scrollTop);
    setIsOpenAlbumInfo(true);
    setWimCharacterNumber(slug);
    setWimCharacterImgNumber(index);
    AudioApi.play({ type: ISongs.SFX_UI_General });
  };

  const getAlbumCharaInfo = (slug: string): number => {
    let num = 0;
    switch (slug) {
      case ResourceTypes.symbolBNormal:
        num = SlotMachine.getInstance().album.b;
        break;
      case ResourceTypes.symbolCNormal:
        num = SlotMachine.getInstance().album.c;
        break;
      case ResourceTypes.symbolDNormal:
        num = SlotMachine.getInstance().album.d;
        break;
      case ResourceTypes.symbolENormal:
        num = SlotMachine.getInstance().album.e;
        break;
      case ResourceTypes.symbolFNormalSp:
        num = SlotMachine.getInstance().album.f;
        break;
      case ResourceTypes.symbolGNormalSp:
        num = SlotMachine.getInstance().album.g;
        break;
      case ResourceTypes.symbolHNormalSp:
        num = SlotMachine.getInstance().album.h;
        break;
      case ResourceTypes.symbolINormalSp:
        num = SlotMachine.getInstance().album.i;
        break;
      case ResourceTypes.symbolJNormalSp:
        num = SlotMachine.getInstance().album.j;
        break;
      default:
        num = 0;
    }
    return num;
  };

  const isAlbumInfoCheck = (slug: string, index: number): boolean => {
    SlotMachine.getInstance().getAlbumInfoData();
    const charaInfo = getAlbumCharaInfo(slug);
    if (index >= charaInfo) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isOpenAlbumInfo) {
      setShowPopup(true);
    } else {
      setTimeout(() => {
        const divElement = document.getElementById('album-list-id');
        divElement!.scrollTop = setAlbumListPosition();
      }, 10);
      setShowPopup(false);
    }
  }, [isOpenAlbumInfo]);

  const handleClose = (directClickToCloseIcon = false) => {
    if (
      setIsModalOpeningInProgress() &&
      setIsModalOpeningInProgress() !== ModalOpeningTypes.ALBUM_MENU &&
      !directClickToCloseIcon
    ) {
      return;
    }

    if (directClickToCloseIcon && setIsOpenAlbumInfo()) {
      setIsOpenAlbumInfo(false);
      AudioApi.play({ type: ISongs.SFX_UI_Close });
      return;
    }

    if (!setIsOpenAlbumInfo()) {
      const divElement = document.getElementById('album-list-id');
      setAlbumListPosition(divElement!.scrollTop);
    }

    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.SFX_UI_MenuOpen)) {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
        if (directClickToCloseIcon) {
          eventManager.emit(EventTypes.TOGGLE_UI_MENU);
        }
      }
      setIsOpenAlbumInfo(false);
    }, 0);
    setIsOpenAlbumModal(false);
  };

  return (
    <>
      {!showPopup && (
        <div className={styles['album-list']} id={'album-list-id'}>
          <section>
            <div>
              {albumIcon.map(({ slug }) => (
                <div key={slug} className={styles['album-list__item']}>
                  <div className={styles['img']}>
                    <img
                      className={styles['img__chara']}
                      draggable="false"
                      alt={slug}
                      src={Resources.getSource(slug)}
                    />
                    <div className={styles['img']}>
                      {isAlbumInfoCheck(slug, 0) ? (
                        <img
                          className={styles['img__imgHeartBig']}
                          draggable="false"
                          alt={slug}
                          src={Resources.getSource('closenessLevelColor1')}
                          onClick={() => handleImgClick(slug, WinCharacterImg.img1)}
                          onMouseOver={() => {
                            if (!isMobileDevice()) {
                              AudioApi.play({ type: ISongs.SFX_UI_Hover });
                            }
                          }}
                        />
                      ) : (
                        <img
                          className={styles['img__imgHeart']}
                          draggable="false"
                          alt={slug}
                          src={Resources.getSource('closenessLevelBase')}
                        />
                      )}
                      {isAlbumInfoCheck(slug, 1) ? (
                        <img
                          className={styles['img__imgHeart2']}
                          draggable="false"
                          alt={slug}
                          src={Resources.getSource('closenessLevelColor2')}
                        />
                      ) : (
                        <img
                          className={styles['img__imgHeart2']}
                          draggable="false"
                          alt={slug}
                          src={Resources.getSource('closenessLevelBase')}
                        />
                      )}
                      {isAlbumInfoCheck(slug, 2) ? (
                        <img
                          className={styles['img__imgHeartBig']}
                          draggable="false"
                          alt={slug}
                          src={Resources.getSource('closenessLevelColor3')}
                          onClick={() => handleImgClick(slug, WinCharacterImg.img2)}
                          onMouseOver={() => {
                            if (!isMobileDevice()) {
                              AudioApi.play({ type: ISongs.SFX_UI_Hover });
                            }
                          }}
                        />
                      ) : (
                        <img
                          className={styles['img__imgHeart']}
                          draggable="false"
                          alt={slug}
                          src={Resources.getSource('closenessLevelBase')}
                        />
                      )}
                      {isAlbumInfoCheck(slug, 3) ? (
                        <img
                          className={styles['img__imgHeart2']}
                          draggable="false"
                          alt={slug}
                          src={Resources.getSource('closenessLevelColor4')}
                        />
                      ) : (
                        <img
                          className={styles['img__imgHeart2']}
                          draggable="false"
                          alt={slug}
                          src={Resources.getSource('closenessLevelBase')}
                        />
                      )}
                      {isAlbumInfoCheck(slug, 4) ? (
                        <img
                          className={styles['img__imgHeartBig']}
                          draggable="false"
                          alt={slug}
                          src={Resources.getSource('closenessLevelColor5')}
                          onClick={() => handleImgClick(slug, WinCharacterImg.img3)}
                          onMouseOver={() => {
                            if (!isMobileDevice()) {
                              AudioApi.play({ type: ISongs.SFX_UI_Hover });
                            }
                          }}
                        />
                      ) : (
                        <img
                          className={styles['img__imgHeart']}
                          draggable="false"
                          alt={slug}
                          src={Resources.getSource('closenessLevelBase')}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      )}
      {showPopup && (
        <Popup
          id="popup"
          className={setIsOpenAlbumInfo() ? 'popup2' : 'popup'}
          showNow={showPopup}
          setShowNow={() => handleClose()}
        >
          <div className="popup__title">{t('albumBtn')}</div>
          <Button intent="close" className="popup__close" onClick={() => handleClose(true)} />
          <div className={styles['bonus-list']}>
            <div>
              <img
                className={styles['img__bonusImg']}
                draggable="false"
                alt={winCharacterTbl[wimCharacterNumber]![wimCharacterImgNumber]}
                src={Resources.getSource(winCharacterTbl[wimCharacterNumber]![wimCharacterImgNumber])}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default CharaTable;
