import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setCurrentBonus,
  setIsModalOpeningInProgress,
  setIsOpenAlbumInfo,
  setIsOpenAlbumModal,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
} from '../../gql/cache';
import { calcPercentage, getGameModeByBonusId, setSlotsStopDisplayVisible } from '../../utils';
import { isFreeSpinsMode } from '../../utils/helper';
import { eventManager } from '../config';

import Button from '.';

class MenuBtn extends Button {
  private gameMode: GameMode;

  private isAutoSpins: boolean;

  private isSpinInProgress: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  private isSceneChange: boolean;

  private isOpenPopup: boolean;

  constructor() {
    super('menu', 75);
    this.interactive = !this.isDisabled;
    this.gameMode = setBrokenGame() ? getGameModeByBonusId(setCurrentBonus().bonusId) : GameMode.REGULAR;
    this.isAutoSpins = false;
    this.isSpinInProgress = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;
    this.isSceneChange = false;
    this.isOpenPopup = false;

    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREE_SPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_SCENE_CHANGE, (isSceneChange: boolean) => {
      this.isSceneChange = isSceneChange;
      this.handleDisable();
    });
    eventManager.on(EventTypes.POPUP_BG_VISIBLE, (isOpenPopup: boolean) => {
      this.isOpenPopup = isOpenPopup;
      this.handleDisable();
    });

    eventManager.on(EventTypes.UI_VISIBLE, () => {
      this.visible = !this.visible;
    });
  };

  private handleClick = (): void => {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE) {
      setIsModalOpeningInProgress(ModalOpeningTypes.MENU);

      setIsOpenMenuModal({
        isOpen: !setIsOpenMenuModal().isOpen,
        type: 'menu',
      });
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      setIsOpenAlbumModal(false);
      setIsOpenAlbumInfo(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      setSlotsStopDisplayVisible(true);
      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  };

  private handleDisable = (): void => {
    this.setDisable(
      isFreeSpinsMode(this.gameMode) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isFreeSpinsWin ||
        this.isSlotBusy ||
        this.isSceneChange ||
        this.isOpenPopup,
    );
  };

  public override handlePosition(): void {
    let x = calcPercentage(this.applicationSize.width, 4.5);
    let y = this.isMobile
      ? calcPercentage(this.applicationSize.height, 94)
      : calcPercentage(this.applicationSize.height, 87);
    this.setSize(calcPercentage(this.applicationSize.width, 5.4));

    if (this.isPortraitMode) {
      this.setSize(calcPercentage(this.underGameContainerSpaceHeight, 20));
      x = calcPercentage(this.underGameContainerSpaceHeight, 20);
      y = this.gameContainerBottomPosition + calcPercentage(this.underGameContainerSpaceHeight, 80);
    }

    if (this.isLandscapeMode) {
      this.setSize(calcPercentage(this.applicationSize.height, 10.4));
      x = calcPercentage(this.applicationSize.width, 7);
      y = calcPercentage(this.applicationSize.height, 87.5);
    }

    this.x = x;
    this.y = y;
  }
}

export default MenuBtn;
