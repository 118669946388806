import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { setIsOpenPopup } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

class Backdrop extends ViewContainer {
  private backdrop = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.backdrop));

  constructor() {
    super();

    this.addChild(this.backdrop);
    this.backdrop.visible = false;
    this.backdrop.alpha = 0.75;
    this.backdrop.interactive = true;
    this.expandBackdropOverScreen();

    this.trackBuyFeaturePopupVisibility();
  }

  public trackBuyFeaturePopupVisibility(): void {
    eventManager.on(EventTypes.POPUP_BG_VISIBLE, (visible: boolean) => {
      this.backdrop.visible = visible;
      setIsOpenPopup(visible);
    });
  }

  // Workaround to position backdrop over view in all landscapes
  public expandBackdropOverScreen(): void {
    this.backdrop.scale.set(4);
    this.x = -(this.backdrop.width / 2);
    this.y = -(this.backdrop.height / 2);
  }
}
export default Backdrop;
