import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../../resources.d';
import ViewContainer from '../../components/container';
import {
  REELS_FRAME_ADJUSTMENT_POS_X,
  REELS_FRAME_ADJUSTMENT_POS_Y,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
} from '../../config';

class ReelsFrame extends ViewContainer {
  private frameTexture = PIXI.Texture.from(ResourceTypes.reelframeBase);

  private frame: PIXI.Sprite;

  constructor() {
    super();
    this.frame = this.initFrame();

    this.x = REELS_FRAME_ADJUSTMENT_POS_X;
    this.y = REELS_FRAME_ADJUSTMENT_POS_Y;
    this.addChild(this.frame);
  }

  private initFrame(): PIXI.Sprite {
    const frame = new PIXI.Sprite();
    frame.texture = this.frameTexture;
    frame.anchor.set(0.5);
    frame.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    return frame;
  }
}

export default ReelsFrame;
