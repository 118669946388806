import { SlotId } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import { setBrokenGame, setNextResult, setUserLastBetResult } from '../../gql/cache';
import Tween from '../../slotMachine/animations/tween';
import { AFTER_SP_SYMBOL_FIX_DELAY, SlotMachineState, eventManager } from '../../slotMachine/config';
import { Icon } from '../../slotMachine/d';
import { MeterKind } from '../../slotMachine/meter/config';
import { getScatterCount, getScatterPosition } from '../helper';

export const initSetMeter = (slotId: SlotId, spinResult?: Icon[]): void => {
  let result: Icon[] = [];

  if (spinResult === undefined) {
    result = setNextResult()!.bet.result.spinResult;
  } else {
    result = spinResult;
  }
  const scatterCount = getScatterCount(result);
  const scatterPosition = getScatterPosition(result);

  let multiPlier = 0;
  if (setNextResult() != null) {
    multiPlier = setNextResult()?.bet.data.features.gameRoundStore.multiplier!;
  }
  if (setBrokenGame()) {
    multiPlier = setUserLastBetResult().data.features.gameRoundStore.multiplier;
  }
  let heart = 0;
  if (setBrokenGame()) {
    heart = setUserLastBetResult().data.features.gameRoundStore.heart;
  } else {
    heart = setNextResult()?.bet.data.features.gameRoundStore.heart!;
  }

  let isIdle = false;

  switch (slotId) {
    case SlotId.C:
    case SlotId.F: {
      eventManager.emit(EventTypes.INIT_METER_SET, multiPlier, MeterKind.SlotId_C_F);
      isIdle = true;
      break;
    }
    case SlotId.E:
    case SlotId.I:
      multiPlier = 0;
      eventManager.emit(EventTypes.INIT_METER_SET, multiPlier, MeterKind.SlotId_E_I);
      isIdle = true;
      break;
    case SlotId.D:
    case SlotId.J: {
      eventManager.emit(EventTypes.INIT_METER_SET, multiPlier, MeterKind.SlotId_D_J);
      if (Math.max(...scatterCount) >= 4) {
        eventManager.emit(EventTypes.SET_METER, multiPlier, heart);
        const delay = Tween.createDelayAnimation(1000);
        delay.addOnComplete(() => {
          eventManager.emit(EventTypes.SET_INIT_SCATTER_METER, scatterCount, scatterPosition);
        });
        delay.start();
      } else {
        isIdle = true;
      }
      break;
    }
    case SlotId.B:
    case SlotId.H:
    case SlotId.G: {
      eventManager.emit(EventTypes.INIT_METER_SET, multiPlier, MeterKind.SlotId_B_H_G);
      if (Math.max(...scatterCount) >= 4) {
        eventManager.emit(EventTypes.SET_METER, multiPlier, heart);
        const delay = Tween.createDelayAnimation(1000);
        delay.addOnComplete(() => {
          eventManager.emit(EventTypes.SET_INIT_SCATTER_METER, scatterCount, scatterPosition);
        });
        delay.start();
      } else {
        isIdle = true;
      }
      break;
    }
    default:
      break;
  }

  if (isIdle) {
    const spSymbolDisplayDelay = Tween.createDelayAnimation(AFTER_SP_SYMBOL_FIX_DELAY);
    spSymbolDisplayDelay.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
    });
    spSymbolDisplayDelay.start();
  }
};

export const getCoinAmount = (nextResult: ISettledBet, isScatter: boolean, isExpand: boolean): number => {
  const payLine = nextResult.paylines;

  let rtn = 0;

  payLine.forEach((line) => {
    if (isExpand) {
      if (line.lineId == null) {
        // expand win
        if (
          line.winPositions.length > 0 &&
          setNextResult()?.bet.result.spinResult[line.winPositions[0]!]!.id != SlotId.WL
        ) {
          rtn = line.amount * 10;
        }
      }
    } else {
      if (line.lineId != null) {
        // normal win
        if (line.winPositions.length > 0) {
          rtn += line.amount;
        }
      } else {
        if (
          isScatter &&
          line.winPositions.length > 0 &&
          setNextResult()?.bet.result.spinResult[line.winPositions[0]!]!.id === SlotId.WL
        ) {
          // scatter win
          rtn += line.amount * 10;
        }
      }
    }
  });
  return rtn;
};

export const easeOutBaseQuint = (x: number): number => {
  return 1 - Math.pow(1 - x, 7);
};

export const easeOutLogoQuint = (x: number): number => {
  return 1 - Math.pow(1 - x, 3);
};
