import type React from 'react';

import type { IGridProps } from './d';
import styles from './grid.module.scss';

const Grid: React.FC<IGridProps> = (props) => {
  const { className = '', children } = props;

  return (
    <div className={`${styles['container']} ${className}`}>
      <div className={`${styles['row']} ${className}-row`}>{children}</div>
    </div>
  );
};

export default Grid;
