import { LinkedListContainer } from '@pixi/particle-emitter';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinsMode } from '../../utils';
import Tween from '../animations/tween';
import { eventManager } from '../config';

import Coin from './coin';
import { ANIMATION_DELAY, MAX_IMAGE_COUNT, WIND_CHANGE_COUNT, moveParameter } from './config';

class CoinParticle extends LinkedListContainer {
  private flower!: Coin;
  private aryImg: Coin[] = [];
  private count = 0;

  constructor() {
    super();
    this.visible = false;
    this.setImages();
    this.flow_start();
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeMode.bind(this));
    this.position.set(0, 0);
  }

  private setImages() {
    for (let i = 0; i < MAX_IMAGE_COUNT; i++) {
      if (this.count === 0) {
        this.flower = new Coin(PIXI.Texture.from(ResourceTypes.coin1));
        this.count++;
      } else if (this.count === 1) {
        this.flower = new Coin(PIXI.Texture.from(ResourceTypes.coin4));
        this.count++;
      } else if (this.count === 2) {
        this.flower = new Coin(PIXI.Texture.from(ResourceTypes.coin8));
        this.count = 0;
      } else {
        this.flower = new Coin(PIXI.Texture.from(ResourceTypes.coin1));
      }
      this.flower.setImage(moveParameter);
      this.aryImg.push(this.flower);
      this.addChild(this.flower);
    }
  }

  private flow_start() {
    const delay = Tween.createDelayAnimation(ANIMATION_DELAY);
    let windChangeCount = 0;
    delay.addOnStart(() => {
      for (let i = 0; i < MAX_IMAGE_COUNT; i++) {
        this.aryImg[i]!.flow(moveParameter);
        // if (setGameMode() === GameMode.FREE_SPINS && MAX_IMAGE_COUNT > i) {
        //   this.aryImg[i]!.visible = true;
        // } else if (
        //   (setGameMode() === GameMode.FREE_SPIN_B || setGameMode() === GameMode.FREE_SPIN_C) &&
        //   IMAGE_COUNT_3 > i
        // ) {
        //   this.aryImg[i]!.visible = true;
        // } else if (
        //   (setGameMode() === GameMode.FREE_SPIN_D || setGameMode() === GameMode.FREE_SPIN_E) &&
        //   IMAGE_COUNT_2 > i
        // ) {
        //   this.aryImg[i]!.visible = true;
        // } else if (IMAGE_COUNT_1 > i) {
        this.aryImg[i]!.visible = true;
        // } else {
        //   this.aryImg[i]!.visible = false;
        // }
        if (windChangeCount > WIND_CHANGE_COUNT) {
          this.windChange();
          windChangeCount = 0;
        }
      }
    });
    delay.addOnComplete(() => {
      delay.start();
      windChangeCount++;
    });
    delay.start();
  }

  private windChange() {
    const wind = Math.random() * (moveParameter.windMax - moveParameter.windMin) + moveParameter.windMin;
    for (let i = 0; i < MAX_IMAGE_COUNT; i++) {
      this.aryImg[i]!.setWind(wind);
    }
  }

  private changeMode(settings: { mode: GameMode }) {
    if (isFreeSpinsMode(settings.mode)) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }
}
export default CoinParticle;
