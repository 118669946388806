import type React from 'react';

import { isMobileDevice } from '../../../utils/helper/helper';

import type { IRangeSlider } from './d';
import styles from './rangeSlider.module.scss';

export const onChangeRangeSlider =
  (fn: (v: number) => void) =>
  (event: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = event;

    fn(+value);
  };

export const getPercent = (value: number, min: number, max: number): number => {
  const a = 100 / (max - min);

  return (value - min) * a;
};

const RangeSlider: React.FC<IRangeSlider> = (props) => {
  const {
    className = '',
    onChange,
    hasLabel = false,
    value = 0,
    min = 0,
    max = 100,
    step = 1,
    disabled = false,
    isDefault = false,
  } = props;

  return (
    <div
      className={`${styles['slider-range']} ${className} ${disabled ? styles['disabled'] : ''} ${
        isDefault ? styles['isDefault'] : ''
      }`}
    >
      {hasLabel && (
        <div className={styles['slider-label'] as string}>
          <div className={styles['slider-label__item'] as string}>{min}</div>
          <div className={styles['slider-label__item'] as string}>{max}</div>
        </div>
      )}

      <div className={(isMobileDevice() ? styles['mobileSlider-wrap'] : styles['slider-wrap']) as string}>
        <div
          className={
            (isMobileDevice() ? styles['mobileSlider-wrap__progress'] : styles['slider-wrap__progress']) as string
          }
          style={{ width: `${getPercent(value, min, max)}%` }}
        />
        <input
          className={(isMobileDevice() ? styles['mobileSlider'] : styles['slider']) as string}
          type="range"
          onChange={onChangeRangeSlider(onChange)}
          value={value}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default RangeSlider;
