import _ from 'lodash';

import { AudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  BG_Music_Base_Loop = '018_01_BG_Music_Base_Loop',
  BG_Music_Melo_Loop = '018_02_BG_Music_Melo_Loop',
  FS_Select_Loop = '018_03_FS_Select_Loop',
  FS_Music_Loop = '018_04_FS_Music_Loop',
  TotalWinBanner = '018_05_TotalWinBanner',
  BigWin_Loop = '018_06_BigWin_Loop',
  BigWin_End = '018_07_BigWin_End',
  Win_Loop = '018_08_Win_Loop',
  Win_End = '018_09_Win_End',
  FS_Trigger = '018_10_FS_Trigger',
  BonusStop_1 = '018_11_BonusStop_1',
  BonusStop_2 = '018_12_BonusStop_2',
  BonusStop_3 = '018_13_BonusStop_3',
  BonusStop_4 = '018_14_BonusStop_4',
  BonusStop_5 = '018_15_BonusStop_5',
  LongSpin = '018_16_LongSpin',
  SpecialStop = '018_17_SpecialStop',
  Book = '018_18_Book',
  EffectMove = '018_19_EffectMove',
  EffectMove2 = '018_19_EffectMove2',
  EffectMove3 = '018_19_EffectMove3',
  MeterLit = '018_20_MeterLit',
  MeterLit2 = '018_20_MeterLit2',
  MultiUp_1 = '018_21_MultiUp_1',
  MultiUp_2 = '018_22_MultiUp_2',
  Expand = '018_23_Expand',
  FsReset = '018_24_FsReset',
  Wipe = '018_25_Wipe',
  SE_Yocho_Phoenix = 'SE_Yocho_Phoenix',
  SFX_BuyFeature = 'SFX_BuyFeature',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_Win_Big = 'SFX_Win_Big',
  SFX_Win_Epic = 'SFX_Win_Epic',
  SFX_Win_Great = 'SFX_Win_Great',
  SFX_Win_Mega = 'SFX_Win_Mega',
}

export enum ISongsVoice {}

export const audioSprite: AudioSprite = {
  [ISongs.BG_Music_Base_Loop]: [0, 57600.04535147392, true],
  [ISongs.BG_Music_Melo_Loop]: [59000, 57600.04535147392, true],
  [ISongs.FS_Select_Loop]: [118000, 7111.111111111114, true],
  [ISongs.FS_Music_Loop]: [127000, 64000.04535147394, true],
  [ISongs.TotalWinBanner]: [193000, 4625.4195011337915],
  [ISongs.BigWin_Loop]: [199000, 33488.435374149674, true],
  [ISongs.BigWin_End]: [234000, 4800.430839002274],
  [ISongs.Win_Loop]: [240000, 902.6303854875266, true],
  [ISongs.Win_End]: [242000, 918.4353741496523],
  [ISongs.FS_Trigger]: [244000, 879.841269841279],
  [ISongs.BonusStop_1]: [246000, 1771.678004535147],
  [ISongs.BonusStop_2]: [249000, 1771.678004535147],
  [ISongs.BonusStop_3]: [252000, 1771.678004535147],
  [ISongs.BonusStop_4]: [255000, 1771.678004535147],
  [ISongs.BonusStop_5]: [258000, 1771.678004535147],
  [ISongs.LongSpin]: [261000, 3800.839002267594],
  [ISongs.SpecialStop]: [266000, 1392.8117913832239],
  [ISongs.Book]: [269000, 582.8798185941082],
  [ISongs.EffectMove]: [271000, 1366.6666666666742],
  [ISongs.EffectMove2]: [271000, 1366.6666666666742],
  [ISongs.EffectMove3]: [271000, 1366.6666666666742],
  [ISongs.MeterLit]: [274000, 612.5850340135912],
  [ISongs.MeterLit2]: [274000, 612.5850340135912],
  [ISongs.MultiUp_1]: [276000, 1228.752834467116],
  [ISongs.MultiUp_2]: [279000, 1546.666666666681],
  [ISongs.Expand]: [282000, 1875.6462585034228],
  [ISongs.FsReset]: [285000, 403.8548752834572],
  [ISongs.Wipe]: [287000, 3357.528344671209],
  [ISongs.SE_Yocho_Phoenix]: [292000, 4033.33333333336],
  [ISongs.SFX_BuyFeature]: [298000, 666.6666666666856],
  [ISongs.SFX_SM_CountUp_End]: [300000, 933.4693877551103],
  [ISongs.SFX_SM_CountUp_Loop]: [302000, 399.7505668934309, true],
  [ISongs.SFX_UI_AutoSpin]: [304000, 320.47619047619946],
  [ISongs.SFX_UI_BetChange]: [306000, 32.08616780045759],
  [ISongs.SFX_UI_Close]: [308000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [310000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [312000, 151.76870748297233],
  [ISongs.SFX_UI_MaxBet]: [314000, 122.92517006801518],
  [ISongs.SFX_UI_MenuOpen]: [316000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [318000, 249.59183673468033],
  // ↓ [ISongs.SFX_UI_SpinStop]: [320000, 151.02040816327644],
  [ISongs.SFX_UI_SpinStop]: [319990, 161.02040816327644],
  // ↑ Lag at sound start causes sound crackling, which is avoided by delaying the sound by 0.1f.
  [ISongs.SFX_Win_Big]: [322000, 1622.3356009070358],
  [ISongs.SFX_Win_Epic]: [325000, 1862.2675736961583],
  [ISongs.SFX_Win_Great]: [328000, 2118.390022675726],
  [ISongs.SFX_Win_Mega]: [332000, 1696.1678004535088],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BG_Music_Base_Loop]: 0.3,
  [ISongs.BG_Music_Melo_Loop]: 0.3,
  [ISongs.FS_Select_Loop]: 0.3,
  [ISongs.FS_Music_Loop]: 0.3,
  [ISongs.TotalWinBanner]: 0.3,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.Win_Loop]: 0.3,
  [ISongs.Win_End]: 0.3,
  [ISongs.FS_Trigger]: 0.3,
  [ISongs.BonusStop_1]: 0.3,
  [ISongs.BonusStop_2]: 0.3,
  [ISongs.BonusStop_3]: 0.3,
  [ISongs.BonusStop_4]: 0.3,
  [ISongs.BonusStop_5]: 0.3,
  [ISongs.LongSpin]: 0.5,
  [ISongs.SpecialStop]: 0.7,
  [ISongs.Book]: 0.6,
  [ISongs.EffectMove]: 0.5,
  [ISongs.EffectMove2]: 0.5,
  [ISongs.EffectMove3]: 0.5,
  [ISongs.MeterLit]: 0.6,
  [ISongs.MeterLit2]: 0.6,
  [ISongs.MultiUp_1]: 0.3,
  [ISongs.MultiUp_2]: 0.8,
  [ISongs.Expand]: 0.5,
  [ISongs.FsReset]: 0.3,
  [ISongs.Wipe]: 0.3,
  [ISongs.SE_Yocho_Phoenix]: 0.3,
  [ISongs.SFX_BuyFeature]: 0.3,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.3,
  [ISongs.SFX_UI_AutoSpin]: 0.3,
  [ISongs.SFX_UI_BetChange]: 0.3,
  [ISongs.SFX_UI_Close]: 0.3,
  [ISongs.SFX_UI_General]: 0.3,
  [ISongs.SFX_UI_Hover]: 0.3,
  [ISongs.SFX_UI_MaxBet]: 0.3,
  [ISongs.SFX_UI_MenuOpen]: 0.3,
  [ISongs.SFX_UI_SpinStart]: 0.3,
  [ISongs.SFX_UI_SpinStop]: 0.3,
  [ISongs.SFX_Win_Big]: 0.3,
  [ISongs.SFX_Win_Epic]: 0.3,
  [ISongs.SFX_Win_Great]: 0.3,
  [ISongs.SFX_Win_Mega]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
