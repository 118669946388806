import type React from 'react';

import type { IItemProps } from './d';
import styles from './grid.module.scss';

const Item: React.FC<IItemProps> = (props) => {
  const { className = '', children, width, alignX = 'start', alignY = 'center', position } = props;

  const getClassNames: string = [
    styles['col'],
    styles[`col-${width}`],
    styles[`justify-content-${alignX}`],
    styles[`align-items-${alignY}`],
    styles[`position-${position}`],
    className,
  ].join(' ');

  return <div className={getClassNames}>{children}</div>;
};

export default Item;
