import * as PIXI from 'pixi.js';

import { EventTypes, MessageWinBannerProps } from '../../global.d';
import { setCurrentBonus, setIsMobile, setNextResult } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { getUserAlbumInfo } from '../../utils/helper';
import Animation from '../animations/animation';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { MESSAGE_BANNER_MOBILE_SCALE, SLOT_WIDTH, eventManager } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  CLOSENESS_LEVEL_COLOR,
  CLOSENESS_LEVEL_X,
  CLOSENESS_SCALE,
  LEFT_RIGHT_OFFSET,
  MESSAGE_BANNER_FADE_IN_ALPHA,
  MESSAGE_BANNER_FADE_IN_X,
  MESSAGE_BANNER_FLASH_DELAY,
  MESSAGE_BANNER_LEVEL_UP_CHARACTER,
  MESSAGE_BANNER_LEVEL_UP_CLOSENESS,
  MESSAGE_BANNER_POS_X,
  MESSAGE_BANNER_POS_Y,
  MESSAGE_TOTAL_ROUND_BANNER_WIN_AMOUNT_Y,
  MESSAGE_WIN_BANNER_TITLE_Y,
  MESSAGE_WIN_BANNER_WIN_AMOUNT_Y,
  SymbolB,
  SymbolC,
  SymbolD,
  SymbolE,
  SymbolF,
  SymbolG,
  SymbolH,
  SymbolI,
  SymbolJ,
  TOTAL_WIN_Z_INDEX,
  resourceInfo,
} from './config';
import { totalRoundAmountTextStyles, totalWinAmountTextStyles, totalWinTitleStyles } from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected override props: MessageWinBannerProps;

  private title?: PIXI.Text;

  private totalWin?: PIXI.Text;

  private closenessLevel: PIXI.Sprite[] = [];

  private charaCounter: number;

  private rect: PIXI.Graphics;

  private totalRoundText?: PIXI.Text;

  private bookAnimationFlash: SpineAnimation | undefined = undefined;

  constructor(props: MessageWinBannerProps) {
    super(props);
    this.props = props;
    this.sortableChildren = true;
    this.position.x = MESSAGE_BANNER_POS_X;
    this.position.y = MESSAGE_BANNER_POS_Y;
    this.charaCounter = 0;
    this.rect = new PIXI.Graphics();
    this.initBookAnimationFlash();
    if (setIsMobile()) {
      this.scale.set(MESSAGE_BANNER_MOBILE_SCALE);
    }
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.onSpaceKey.bind(this));
  }

  private onSpaceKey(): void {
    if (!this.delayDisplay) return;
    if (this.props.callback) {
      this.props.callback();
      this.props.callback = undefined;
    }
    if (!this.props.preventDefaultDestroy) this.handleDestroy();
  }

  public override init(): MessageWinBanner {
    this.charaCounter =
      getUserAlbumInfo(setNextResult()?.bet.data.features.gameRoundStore.id!, 0) > 0
        ? getUserAlbumInfo(setNextResult()?.bet.data.features.gameRoundStore.id!, 0)
        : 1;
    const chara = this.getCharaResource(this.charaCounter - 1);
    super.init(chara.resource);

    for (let i = 0; i < CLOSENESS_LEVEL_X.length; i++) {
      this.closenessLevel[i] = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.closenessLevelBase));
      this.closenessLevel[i]?.anchor.set(0.5);
      let closenessPos = 0;
      if (chara.isLeft) {
        closenessPos = CLOSENESS_LEVEL_X[i]! + LEFT_RIGHT_OFFSET;
      } else {
        closenessPos = CLOSENESS_LEVEL_X[i]! - LEFT_RIGHT_OFFSET;
      }
      this.closenessLevel[i]?.position.set(closenessPos, this.banner?.height);
      this.closenessLevel[i]?.scale.set(CLOSENESS_SCALE[i]);
      this.addChild(this.closenessLevel[i]!);
    }

    this.title = this.initTitle(this.props.title, chara.isLeft);
    this.totalWin = this.initWinTotal(this.props.totalWin, chara.isLeft);
    this.totalRoundText = this.initTotalRound(setCurrentBonus().totalRound, chara.isLeft);
    this.addChild(this.title, this.totalWin, this.totalRoundText);

    if (this.charaCounter > 5) {
      for (let i = 0; i < CLOSENESS_LEVEL_COLOR.length; i++) {
        this.closenessLevel[i]!.texture = PIXI.Texture.from(CLOSENESS_LEVEL_COLOR[i]!);
      }
    } else {
      for (let i = 0; i < this.charaCounter - 1 && i < CLOSENESS_LEVEL_COLOR.length; i++) {
        this.closenessLevel[i]!.texture = PIXI.Texture.from(CLOSENESS_LEVEL_COLOR[i]!);
      }
    }

    this.setZIndex();

    const animation = new AnimationGroup();
    animation.addAnimation(this.totalWinFadeIn(MESSAGE_BANNER_FADE_IN_X));
    animation.addAnimation(this.totalWinFadeInAlpha(MESSAGE_BANNER_FADE_IN_ALPHA));
    animation.addOnComplete(() => {
      eventManager.emit(EventTypes.TOTAL_WIN_DISPLAY_AVATAR);
      this.totalWinDisplay();
    });
    animation.start();

    return this;
  }

  public totalWinDisplay(): void {
    this.rect.beginFill(0x000000);
    this.rect.drawRect(0, 0, this.banner!.width, this.banner!.height);
    this.rect.position.set(-this.banner!.width / 2, 0);
    this.rect.alpha = 0.5;

    const animation = new AnimationGroup();
    animation.addAnimation(
      this.totalWindDropShadow(250, TweenProperties.X, -this.banner!.width / 2, -this.banner!.width / 2 + 10),
    );
    animation.addAnimation(this.totalWindDropShadow(250, TweenProperties.Y, 0, 10));
    animation.start();

    this.addChild(this.rect);

    if (this.charaCounter > 5) {
      for (let i = 0; i < CLOSENESS_LEVEL_COLOR.length; i++) {
        this.closenessLevel[i]!.texture = PIXI.Texture.from(CLOSENESS_LEVEL_COLOR[i]!);
      }
    } else {
      for (let i = 0; i < this.charaCounter - 1 && i < CLOSENESS_LEVEL_COLOR.length; i++) {
        this.closenessLevel[i]!.texture = PIXI.Texture.from(CLOSENESS_LEVEL_COLOR[i]!);
      }
      const delayFlash = Tween.createDelayAnimation(MESSAGE_BANNER_FLASH_DELAY);
      delayFlash.addOnComplete(() => {
        this.bookAnimationFlash!.setAnimation('totalwin_flash', false);
        if (this.charaCounter === 3 || this.charaCounter === 5) {
          this.bookAnimationFlash!.start();
          const delayClosenessLevel = Tween.createDelayAnimation(MESSAGE_BANNER_LEVEL_UP_CHARACTER);
          delayClosenessLevel.addOnComplete(() => {
            const delayUpdate = Tween.createDelayAnimation(MESSAGE_BANNER_LEVEL_UP_CLOSENESS);
            delayUpdate.addOnComplete(() => {
              const index = this.charaCounter - 1;
              this.closenessLevel[index]!.texture = PIXI.Texture.from(CLOSENESS_LEVEL_COLOR[index]!);
              const chara = this.getCharaResource(this.charaCounter);
              this.banner!.texture = PIXI.Texture.from(chara.resource);
              if (chara.isLeft) {
                this.title!.x = LEFT_RIGHT_OFFSET;
                this.totalWin!.x = LEFT_RIGHT_OFFSET;
                this.totalRoundText!.x = LEFT_RIGHT_OFFSET;
              } else {
                this.title!.x = -LEFT_RIGHT_OFFSET;
                this.totalWin!.x = -LEFT_RIGHT_OFFSET;
                this.totalRoundText!.x = -LEFT_RIGHT_OFFSET;
              }
              for (let i = 0; i < CLOSENESS_LEVEL_X.length; i++) {
                let closenessPos = 0;
                if (chara.isLeft) {
                  closenessPos = CLOSENESS_LEVEL_X[i]! + LEFT_RIGHT_OFFSET;
                } else {
                  closenessPos = CLOSENESS_LEVEL_X[i]! - LEFT_RIGHT_OFFSET;
                }
                this.closenessLevel[i]?.position.set(closenessPos, this.banner?.height);
              }
            });
            delayUpdate.start();
          });
          delayClosenessLevel.start();
        } else {
          const index = this.charaCounter - 1;
          this.closenessLevel[index]!.texture = PIXI.Texture.from(CLOSENESS_LEVEL_COLOR[index]!);
        }
      });
      delayFlash.start();
      this.setZIndex();
    }
  }

  private setZIndex(): void {
    this.rect.zIndex = TOTAL_WIN_Z_INDEX.rect;
    this.banner!.zIndex = TOTAL_WIN_Z_INDEX.banner;
    this.title!.zIndex = TOTAL_WIN_Z_INDEX.title;
    this.totalWin!.zIndex = TOTAL_WIN_Z_INDEX.totalWin;
    this.totalRoundText!.zIndex = TOTAL_WIN_Z_INDEX.totalRound;
    this.closenessLevel.forEach((closeness) => {
      closeness.zIndex = TOTAL_WIN_Z_INDEX.closenessLevel;
    });
  }

  private getCharaResource(counter: number): resourceInfo {
    let rtn: resourceInfo;
    let index = 0;
    if (counter <= 2) {
      index = 0;
    } else if (counter <= 4) {
      index = 1;
    } else if (counter > 4) {
      index = 2;
    }

    switch (setNextResult()?.bet.data.features.gameRoundStore.id!) {
      case 'B':
        rtn = SymbolB[index]!;
        break;
      case 'C':
        rtn = SymbolC[index]!;
        break;
      case 'D':
        rtn = SymbolD[index]!;
        break;
      case 'E':
        rtn = SymbolE[index]!;
        break;
      case 'F':
        rtn = SymbolF[index]!;
        break;
      case 'G':
        rtn = SymbolG[index]!;
        break;
      case 'H':
        rtn = SymbolH[index]!;
        break;
      case 'I':
        rtn = SymbolI[index]!;
        break;
      case 'J':
        rtn = SymbolJ[index]!;
        break;
      default:
        rtn = SymbolB[0]!;
    }
    return rtn;
  }

  protected initTitle(titleText: string, isLeft: boolean): PIXI.Text {
    const winTitle = new PIXI.Text(titleText, totalWinTitleStyles);
    winTitle.resolution = 1;
    winTitle.anchor.set(0.5, 0);
    if (isLeft) {
      winTitle.x = LEFT_RIGHT_OFFSET;
    } else {
      winTitle.x = -LEFT_RIGHT_OFFSET;
    }
    winTitle.y = MESSAGE_WIN_BANNER_TITLE_Y;
    if (winTitle.width > 800) winTitle.width = 800;
    return winTitle;
  }

  protected initWinTotal(totalWin: string, isLeft: boolean): PIXI.Text {
    const winTotal = new PIXI.Text(totalWin, totalWinAmountTextStyles);
    winTotal.resolution = 1;
    winTotal.anchor.set(0.5, 0);
    if (isLeft) {
      winTotal.x = LEFT_RIGHT_OFFSET;
    } else {
      winTotal.x = -LEFT_RIGHT_OFFSET;
    }
    winTotal.y = MESSAGE_WIN_BANNER_WIN_AMOUNT_Y;
    return winTotal;
  }

  protected initTotalRound(totalRound: number, isLeft: boolean): PIXI.Text {
    const totalRoundText = new PIXI.Text(totalRound + ' ' + i18n.t<string>('freeSpins'), totalRoundAmountTextStyles);
    totalRoundText.resolution = 1;
    totalRoundText.anchor.set(0.5, 0);
    if (isLeft) {
      totalRoundText.x = LEFT_RIGHT_OFFSET;
    } else {
      totalRoundText.x = -LEFT_RIGHT_OFFSET;
    }
    if (totalRoundText.width > 800) totalRoundText.width = 800;
    totalRoundText.y = MESSAGE_TOTAL_ROUND_BANNER_WIN_AMOUNT_Y;
    return totalRoundText;
  }

  override updateTransform(): void {
    super.updateTransform();
  }

  private totalWinFadeIn(duration: number): Animation {
    const animation = new Tween({
      object: this,
      duration,
      property: TweenProperties.X,
      propertyBeginValue: -1200,
      target: (SLOT_WIDTH * 5) / 2,
      easing: (n) => 1 - Math.pow(1 - n, 7),
    });
    return animation;
  }

  private totalWinFadeInAlpha(duration: number): Animation {
    const animation = new Tween({
      object: this,
      duration,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 1,
      // easing: (n) => 1 - Math.pow(1 - n, 7),
    });
    return animation;
  }

  private totalWindDropShadow(
    duration: number,
    property: TweenProperties,
    BeginVal: number,
    targetVal: number,
  ): Animation {
    const animation = new Tween({
      object: this.rect,
      duration,
      property: property,
      propertyBeginValue: BeginVal,
      target: targetVal,
    });
    return animation;
  }

  private initBookAnimationFlash(): void {
    this.bookAnimationFlash = new SpineAnimation({}, PIXI.Loader.shared.resources['totalwin_flash']!.spineData);
    this.bookAnimationFlash.addOnStart(() => {
      this.addChild(this.bookAnimationFlash!.spine);
      this.bookAnimationFlash!.getSpine().position.set(0, 430);
      this.bookAnimationFlash!.getSpine().zIndex = TOTAL_WIN_Z_INDEX.flash;
    });
  }
}
