import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs, MAPPED_SP_SYMBOLS, SlotId } from '../../config';
import { BgmSoundTypes, EventTypes, bonusIdsStringToGameMode } from '../../global.d';
import {
  setBrokenGame,
  setBrokenGameReelSet,
  setCurrentBonus,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinModeSceneChange,
  setIsOpenedMessageBanner,
  setIsShowSoundToast,
  setNextResult,
  setPrevReelsPosition,
  setSlotConfig,
} from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { getSpinResult5x3 } from '../../utils/helper';
import { initSetMeter } from '../../utils/utils';
import Animation from '../animations/animation';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { BACKGROUND_INSURANCE_SCALE, BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH } from '../background/config';
import {
  REELS_AMOUNT,
  Z_INDEX_AVATAR,
  eventManager,
  getSpSymbolGameModeToSlotId,
  getSpSymbolList,
  spSymbolList,
} from '../config';

import {
  ANIMATIONS,
  ANIMATIONS_STEPS,
  BOOK_ANIMATION_POS_X,
  BOOK_ANIMATION_POS_Y,
  DISPLAY_SHOW_DELAY,
  SP_SYMBOL_DISPLAY_WAIT,
  SP_SYMBOL_LOT_BASE_ALPHA,
  SP_SYMBOL_LOT_BASE_FADE_OUT_TIME,
  SP_SYMBOL_PRESS_START_DELAY,
} from './config';

const handleChangeRestriction = (): void => {
  BgmControl.handleChangeRestriction();
};

class SpSymbolLot extends PIXI.Container {
  private bookAnimationIdle: SpineAnimation | undefined = undefined;

  private bookAnimation: SpineAnimation | undefined = undefined;

  private animationStepIndex: number;

  private hasForceStop = false;

  private hasStop: boolean;

  private spSymbol: PIXI.Sprite;

  private spSymbolDisplayDelay: Animation | null = null;

  private rect: PIXI.Graphics;

  constructor() {
    super();

    this.visible = false;
    this.animationStepIndex = ANIMATIONS.lottery;
    this.hasStop = false;
    this.position.set(BOOK_ANIMATION_POS_X, BOOK_ANIMATION_POS_Y);
    this.sortableChildren = true;
    this.spSymbol = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolBNormal));
    this.rect = new PIXI.Graphics();
    this.rect.beginFill(0x000000);
    this.rect.drawRect(0, 0, BACKGROUND_SIZE_WIDTH, BACKGROUND_SIZE_HEIGHT * BACKGROUND_INSURANCE_SCALE);
    this.rect.position.set(BACKGROUND_SIZE_WIDTH / 2, -BACKGROUND_SIZE_HEIGHT / 2);
    this.rect.alpha = SP_SYMBOL_LOT_BASE_ALPHA;
    this.addChild(this.rect);
    this.initBookAnimationIdle();
    this.initBookAnimation();
    this.pivot.set(0.5);
    this.zIndex = Z_INDEX_AVATAR;

    eventManager.addListener(EventTypes.SP_SYMBOL_LOT_START, this.spSymbolLotStart.bind(this));
    eventManager.addListener(EventTypes.SPACE_KEY_CLOSE_SP_SYMBOL_LOT, this.handleClick.bind(this));
    eventManager.addListener(EventTypes.SP_SYMBOL_IF_VISIBLE, this.spSymbolIfVisible.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));

    this.interactive = true;
    this.on('click', () => {
      this.handleClick();
    });
    this.on('touchstart', () => {
      this.handleClick();
    });
  }

  private handleClick(): void {
    if (setIsFreeSpinModeSceneChange()) return;
    if (setIsShowSoundToast()) {
      AudioApi.changeRestriction(
        false,
        [],
        () => {},
        () => {
          setIsShowSoundToast(false);
          handleChangeRestriction();
        },
      );
    }
    if (this.bookAnimationIdle!.getSpine().visible) {
      this.bookAnimationIdle!.getSpine().visible = false;
      this.startBookAnimation('lottery');
      eventManager.emit(EventTypes.PRESS_START_VISIBLE, false);
    } else if (this.animationStepIndex === ANIMATIONS.idle_ex) {
      this.spSymbolDisplayDelay?.skip();
      this.onCompleteBookAnimation();
    }
  }

  private initBookAnimationIdle(): void {
    this.bookAnimationIdle = new SpineAnimation({}, PIXI.Loader.shared.resources['symbol_lottery']!.spineData);
    this.bookAnimationIdle.addOnStart(() => {
      this.addChild(this.bookAnimationIdle!.spine);
    });
  }

  private initBookAnimation(): void {
    this.bookAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources['symbol_lottery']!.spineData);
    this.bookAnimation.addOnStart(() => {
      this.addChild(this.bookAnimation!.spine);
    });
  }

  private startBookAnimationIdle(anim: string): void {
    this.bookAnimationIdle!.setAnimation(anim, true);
    if (this.bookAnimationIdle?.getSpine()) this.bookAnimationIdle.getSpine().visible = true;
    this.bookAnimationIdle!.start();
  }

  private startBookAnimation(anim: string): void {
    let loop = false;
    if (anim === ANIMATIONS_STEPS[ANIMATIONS.idle_ex]) {
      loop = true;
      setTimeout(() => {
        eventManager.emit(EventTypes.BOOK_INFO_TEXT_VISIBLE, true);
      }, 1);
      this.spSymbolDisplayDelay = Tween.createDelayAnimation(SP_SYMBOL_DISPLAY_WAIT);
      this.spSymbolDisplayDelay.addOnComplete(() => {
        this.onCompleteBookAnimation();
      });
      this.spSymbolDisplayDelay.start();
    }

    this.bookAnimation!.setAnimation(anim, loop);
    if (this.bookAnimation?.getSpine()) this.bookAnimation.getSpine().visible = true;
    if (anim === ANIMATIONS_STEPS[ANIMATIONS.lottery]) {
      this.bookAnimation!.start();
    }
    this.bookAnimation!.complete = [];
    this.bookAnimation!.addOnComplete(() => {
      console.log('============== comp ', anim);
      if (anim === ANIMATIONS_STEPS[ANIMATIONS.lottery]) {
        eventManager.emit(EventTypes.LOT_CONFIRMED);
        this.onCompleteBookAnimation();
      } else if (anim === ANIMATIONS_STEPS[ANIMATIONS.lottery_set]) {
        this.onCompleteBookAnimation();
        setIsOpenedMessageBanner(false);
      }
    });

    this.bookAnimation!.getSpine().hackTextureBySlotName(
      'SPsymbols',
      this.spSymbol.texture,
      this.spSymbol.texture.frame,
    );

    this.bookAnimation!.getSpine().state.addListener({
      event: function (_t, e) {
        if (e.data.name === 'page_start') {
          AudioApi.play({ type: ISongs.Book });
        } else if (e.data.name === 'page_end') {
          AudioApi.stop({ type: ISongs.Book });
          AudioApi.play({ type: ISongs.MultiUp_1 });
        } else if (e.data.name === 'symbol_set') {
          eventManager.emit(EventTypes.SP_SYMBOL_IF_VISIBLE);
          eventManager.emit(EventTypes.BOOK_INFO_TEXT_VISIBLE, false);
          setIsOpenedMessageBanner(false);
          BgmControl.playBgm(BgmSoundTypes.BB);
        }
      },
    });
  }

  private spSymbolIfVisible(): void {
    if (!this.visible) return;
    this.hasStop = true;

    const freeSpinsBonus = SlotMachine.getInstance().getFreeSpinBonus();

    if (setBrokenGame()) {
      eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT, setCurrentBonus().rounds, 0, false);
      const spinResult = getSpinResult5x3({
        reelPositions: setPrevReelsPosition().slice(0, REELS_AMOUNT),
        reelSet: setSlotConfig().reels.find((reelSet) => reelSet.id === setBrokenGameReelSet())!,
        icons: setSlotConfig().icons,
      });
      initSetMeter(getSpSymbolGameModeToSlotId[bonusIdsStringToGameMode[setCurrentBonus().bonusId]!]!, spinResult);
    } else {
      initSetMeter(spSymbolList[freeSpinsBonus?.bonus.reelSetId!]!);
    }
    AudioApi.play({ type: ISongs.MeterLit });
    eventManager.emit(EventTypes.FREE_SPINS_DISPLAY_FLASH);

    const delay = Tween.createDelayAnimation(DISPLAY_SHOW_DELAY);
    delay.addOnComplete(() => {
      eventManager.emit(EventTypes.FREE_SPINS_DISPLAY_SHOW, true);
    });
    delay.start();

    this.visible = false;
  }

  private onCompleteBookAnimation(): void {
    this.animationStepIndex += 1;
    if (this.hasForceStop) {
      this.hasForceStop = false;
    }

    if (this.animationStepIndex === ANIMATIONS.lottery_set) {
      AudioApi.play({ type: ISongs.EffectMove });
      const animation = new AnimationGroup();
      animation.addAnimation(this.getSceneChangeOutAnimation(SP_SYMBOL_LOT_BASE_FADE_OUT_TIME));
      animation.start();
    }

    if (this.animationStepIndex === ANIMATIONS_STEPS.length) {
      this.hasStop = true;
      this.visible = false;
    }

    if (!this.hasStop) {
      this.startBookAnimation(ANIMATIONS_STEPS[this.animationStepIndex]!);
    }
  }

  private spSymbolLotStart(): void {
    setIsOpenedMessageBanner(true);
    this.visible = true;
    this.hasStop = false;
    this.animationStepIndex = 0;
    this.bookAnimation!.getSpine().visible = false;
    this.rect.alpha = SP_SYMBOL_LOT_BASE_ALPHA;
    this.rect.visible = true;
    this.startBookAnimationIdle('idle');

    let slot: SlotId;
    if (setBrokenGame()) {
      slot = getSpSymbolGameModeToSlotId[bonusIdsStringToGameMode[setCurrentBonus().bonusId]!]!;
    } else {
      slot = getSpSymbolList[setNextResult()?.bet.data.features.gameRoundStore.id!]!;
    }

    this.spSymbol.texture = PIXI.Texture.from(MAPPED_SP_SYMBOLS[slot]);

    if (setIsContinueAutoSpinsAfterFeature()) {
      const delay = Tween.createDelayAnimation(SP_SYMBOL_PRESS_START_DELAY);
      delay.addOnComplete(() => {
        eventManager.emit(EventTypes.SPACE_KEY_CLOSE_SP_SYMBOL_LOT);
      });
      delay.start();
    }
  }

  private getSceneChangeOutAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.rect,
      duration,
      property: TweenProperties.ALPHA,
      propertyBeginValue: SP_SYMBOL_LOT_BASE_ALPHA,
      target: 0,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => 1 - Math.pow(1 - n, 7),
    });
    return animation;
  }

  private resize(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    _pivotX: number,
    _pivotY: number,
  ): void {
    this.rect.clear();
    this.rect.beginFill(0x000000);
    this.rect.drawRect(
      0,
      0,
      SlotMachine.getInstance().background.width * (1 / scale),
      SlotMachine.getInstance().background.height * (1 / scale) * BACKGROUND_INSURANCE_SCALE,
    );
    this.rect.position.set(-this.rect.width / 2, -this.rect.height / 2);
    this.rect.alpha = SP_SYMBOL_LOT_BASE_ALPHA;
  }
}

export default SpSymbolLot;
