import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import Tween from '../animations/tween';
import { eventManager } from '../config';

import { FREE_SPIN_UI_FLASH_POS_X, FREE_SPIN_UI_FLASH_POS_Y } from './config';

class FreeSpinUiFlash extends Spine {
  constructor() {
    super(PIXI.Loader.shared.resources['freespin_ui_flash']!.spineData!);

    this.position.set(FREE_SPIN_UI_FLASH_POS_X, FREE_SPIN_UI_FLASH_POS_Y);

    eventManager.addListener(EventTypes.FREE_SPINS_DISPLAY_FLASH, this.flashStart.bind(this));
    this.visible = false;
  }

  public flashStart() {
    const delay = Tween.createDelayAnimation(1);
    delay.addOnComplete(() => {
      this.visible = true;
    });
    delay.start();
    this.state.setAnimation(0, 'freespin_ui_flash', false);
  }
}

export default FreeSpinUiFlash;
