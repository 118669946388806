interface MeterParameter {
  CONTAINER_PORTRAIT_POS_X: number;
  CONTAINER_PORTRAIT_POS_Y: number;
  CONTAINER_LANDSCAPE_POS_X: number;
  CONTAINER_LANDSCAPE_POS_Y: number;
  TEXT_POS_X: number;
  TEXT_POS_Y: number;
  FONT_SIZE: number;
}

export const meterPram: MeterParameter = {
  CONTAINER_PORTRAIT_POS_X: 125,
  CONTAINER_PORTRAIT_POS_Y: -75,
  CONTAINER_LANDSCAPE_POS_X: 125,
  CONTAINER_LANDSCAPE_POS_Y: -75,
  TEXT_POS_X: -40,
  TEXT_POS_Y: -75,
  FONT_SIZE: 80,
};

export enum MeterLevel {
  ZERO,
  ONE,
  TWO,
  THREE,
  FOUR,
  FIVE,
}

export enum MeterKind {
  SlotId_C_F,
  SlotId_E_I,
  SlotId_D_J,
  SlotId_B_H_G,
}

export const METER_ANIMATION_NAME = 'freespin_meter';

const METER_ANIMATIONS_TABLE_SLOT_ID_C_F = ['freespin_base_none'];
const METER_ANIMATIONS_TABLE_SLOT_ID_D_J = [
  'freespin_1meter_none',
  'freespin_1meter_stepup1',
  'freespin_1meter_stepup2',
];
const METER_ANIMATIONS_TABLE_SLOT_ID_B_H_G = [
  'freespin_3meter_none',
  'freespin_3meter_stepup1',
  'freespin_3meter_stepup2',
  'freespin_3meter_stepup3',
  'freespin_3meter_stepup4',
];

export const METER_TABLE: Record<MeterKind, string[]> = {
  [MeterKind.SlotId_C_F]: METER_ANIMATIONS_TABLE_SLOT_ID_C_F,
  [MeterKind.SlotId_E_I]: [],
  [MeterKind.SlotId_D_J]: METER_ANIMATIONS_TABLE_SLOT_ID_D_J,
  [MeterKind.SlotId_B_H_G]: METER_ANIMATIONS_TABLE_SLOT_ID_B_H_G,
};
export const METER_MAX = 'freespin_max';

export const HEART_COUNT_UP_DELAY = 500;
export const MULTIPLIER_UP_BEFORE_DELAY = 400;
export const MULTIPLIER_UP_DELAY = 400;
