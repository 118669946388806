import React from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { eventManager } from '../../slotMachine/config';
import styles from '../Settings/settings.module.scss';
import Button from '../conponentsBfth/button/index';

import HistoryComponent from './HistoryComponent';

export const HistoryIcon: React.FC<{
  setType: React.Dispatch<React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>>;
}> = ({ setType }) => (
  <div className={styles['settings-menu']}>
    <Button
      intent="history"
      onClick={() => {
        setType('history');
        AudioApi.stop({ type: ISongs.SFX_UI_Close });
        AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
        eventManager.emit(EventTypes.TOGGLE_UI_MENU);
      }}
    />
  </div>
);

const History: React.FC<{
  setType: React.Dispatch<React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>>;
  show: boolean;
}> = ({ setType, show }) => {
  const { t } = useTranslation();

  const handleClosePopup = () => {
    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.SFX_UI_MenuOpen)) {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
        eventManager.emit(EventTypes.TOGGLE_UI_MENU);
      }
    }, 0);
    setType('menu');
  };

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <div className="popup__title">{t('gameHistory')}</div>
      <Button intent="close" className="popup__close" onClick={handleClosePopup} />
      <HistoryComponent />
    </div>
  );
};

export default History;
