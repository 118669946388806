import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ResourceTypes } from '../../resources.d';
import SlotMachine from '../../slotMachine';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoFeature' });
  const featuresFreeSpins: string[] = t('FreeSpinDesc', {
    returnObjects: true,
  });
  const featureFreeSpinsType1: string[] = t('FreeSpinType1', {
    returnObjects: true,
  });
  const featureFreeSpinsType2: string[] = t('FreeSpinType2', {
    returnObjects: true,
  });
  const featureFreeSpinsType3: string[] = t('FreeSpinType3', {
    returnObjects: true,
  });
  const featureFreeSpinsType4: string[] = t('FreeSpinType4', {
    returnObjects: true,
  });
  const infoBuyFeatureDesc: string[] = t('infoBuyFeatureDesc', {
    returnObjects: true,
  });
  const infoAlbumDesc: string[] = t('infoAlbumDesc', {
    returnObjects: true,
  });

  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');
  const [infoAlbumIcon, setInfoAlbumIcon] = useState('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins['extract']!;
    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().infoBuyFeatureIcon));
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins['extract']!;
    setInfoAlbumIcon(extractor.base64(SlotMachine.getInstance().infoAlbumIcon));
  }, []);

  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('FreeSpinTitle')}</h1>
      <div className={`${styles['p']} ${styles['left']}`}>
        {featuresFreeSpins.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <div className={styles['img']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoHeartMeter)} />
        <div className={`${styles['p']} ${styles['left']}`}>{t('multiplayerMeterText')}</div>
      </div>
      <section className={styles['feature']}></section>
      <div className={styles['img']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.symbolBNormal)} />
        <img draggable="false" src={Resources.getSource(ResourceTypes.symbolGNormal)} />
        <img draggable="false" src={Resources.getSource(ResourceTypes.symbolHNormal)} />
        <div className={`${styles['p']} ${styles['left']}`}>
          {featureFreeSpinsType1.map((v, i) => (
            <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
          ))}
        </div>
      </div>
      <section className={styles['feature']}></section>
      <div className={styles['img']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.symbolCNormal)} />
        <img draggable="false" src={Resources.getSource(ResourceTypes.symbolFNormal)} />
        <div className={`${styles['p']} ${styles['left']}`}>
          {featureFreeSpinsType2.map((v, i) => (
            <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
          ))}
        </div>
      </div>
      <section className={styles['feature']}></section>
      <div className={styles['img']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.symbolDNormal)} />
        <img draggable="false" src={Resources.getSource(ResourceTypes.symbolJNormal)} />
        <div className={`${styles['p']} ${styles['left']}`}>
          {featureFreeSpinsType3.map((v, i) => (
            <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
          ))}
        </div>
      </div>
      <section className={styles['feature']}></section>
      <div className={styles['img']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.symbolENormal)} />
        <img draggable="false" src={Resources.getSource(ResourceTypes.symbolINormal)} />
        <div className={`${styles['p']} ${styles['left']}`}>
          {featureFreeSpinsType4.map((v, i) => (
            <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
          ))}
        </div>
      </div>
      <div className={`${styles['container']}`}>
        <h2 className={styles['title']}>{t('infoBuyFeatureTitle')}</h2>
        <div className={`${styles['container']}`}>
          <img className="buyFeatureBtn" draggable="false" src={infoBuyFeatureIcon} />
        </div>
      </div>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoBuyFeatureDesc.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <div className={`${styles['container']}`}>
        <h2 className={styles['title']}>{t('infoAlbumTitle')}</h2>
        <div className={`${styles['container']}`}>
          <img className="albumBtn" draggable="false" src={infoAlbumIcon} />
        </div>
      </div>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoAlbumDesc.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div className={styles['gameRules']}>
      <FreeSpinFeature />
    </div>
  );
};

export default Features;
