import { BFTH_COLOR_INTRO } from '../../../slotMachine/config';

export const CAROUSEL_CONTROLS_COLOR = 0xffffff;
export const CAROUSEL_DOTS_DEFAULT_COLOR = '#ffffff';
export const CAROUSEL_DOTS_ACTIVE_COLOR = BFTH_COLOR_INTRO;
export const CAROUSEL_DOTS_BLUR_SIZE = 5;
export const CAROUSEL_DOTS_SIZE = 24;
export const CAROUSEL_DOTS_GAP = 10;
export const CAROUSEL_ARROWS_SIZE = 24;
export const CAROUSEL_ANIMATION_DURATION = 500;
