import i18n from 'i18next';
import { DropShadowFilter } from 'pixi-filters';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { introContents } from '../../config/introContents';
import { EventTypes } from '../../global.d';
import {
  setIsShowSoundToast,
  setIsSoundLoading,
  setIsSoundOn,
  setProgress,
  setSkipIntroScreen,
  setSoundValue,
} from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import Switch from '../../slotMachine/button/switch';
import { eventManager } from '../../slotMachine/config';

import Carousel from './Carousel/index';

const GAP_Y = 15;
const GAP_X = 15;
const SWITCH_LABELCOLOR = 0xffffff;

const handleChangeRestriction = (): void => {
  BgmControl.handleChangeRestriction();
};

class IntroScreen {
  private readonly application: PIXI.Application;

  private container: PIXI.Container;

  private controlsContainer: PIXI.Container;

  private static introScreen: IntroScreen;

  public static initIntroScreen = (application: PIXI.Application): void => {
    IntroScreen.introScreen = new IntroScreen(application);
  };

  public static getInstance = (): IntroScreen => IntroScreen.introScreen;

  private background = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.backgroundBaseNoon));

  private backgroundContainer = new PIXI.Container();

  public okBtn: PIXI.Sprite;

  public soundSwitch: Switch;

  public skipIntroSwitch: Switch;

  private carousel: Carousel;

  private bindedResize = this.resize.bind(this);

  private constructor(application: PIXI.Application) {
    this.application = application;
    this.background.anchor.set(0.5);
    this.backgroundContainer.addChild(this.background);
    this.application.stage.addChild(this.backgroundContainer);
    this.container = new PIXI.Container();
    this.controlsContainer = new PIXI.Container();
    this.carousel = new Carousel(400, introContents);
    this.container.addChild(this.carousel);
    this.okBtn = this.initOkBtn();
    this.controlsContainer.addChild(this.okBtn);
    this.soundSwitch = this.initSoundSwitchBtn();
    this.controlsContainer.addChild(this.soundSwitch);
    this.skipIntroSwitch = this.initSkipIntroSwitchBtn();
    this.controlsContainer.addChild(this.skipIntroSwitch);
    this.container.addChild(this.controlsContainer);
    this.application.stage.addChild(this.container);
    eventManager.addListener(EventTypes.RESIZE, this.bindedResize);

    eventManager.on(EventTypes.SOUND_INITIALIZED, (_isError: boolean) => {
      AudioApi.setSoundState(setIsSoundOn());
    });
  }

  private initOkBtn = (): PIXI.Sprite => {
    const texture = PIXI.Texture.from(ResourceTypes.buttonOk);
    const btn = new PIXI.Sprite(texture);
    btn.anchor.set(0, 0);
    btn.width = 200;
    btn.height = 50;
    btn.y = 0;
    btn.x = 0;
    btn.buttonMode = true;
    btn.interactive = true;

    const dropShadow = new DropShadowFilter({
      color: 0x000000,
      resolution: 4 * PIXI.settings.FILTER_RESOLUTION,
      alpha: 0.4,
    });

    const clickCallback = () => {
      if (AudioApi.restrictionChangedOnIntroScreen) return;
      AudioApi.restrictionChangedOnIntroScreen = true;

      window.setTimeout(() => {
        this.destroy();
        setProgress({ ...setProgress(), wasLoaded: true });
        eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
        if (!AudioApi.isInitialized) {
          eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
        }
        AudioApi.changeRestriction(
          false,
          [],
          () => setIsSoundLoading(true),
          () => {
            setIsShowSoundToast(false);
            handleChangeRestriction();
          },
        );
      }, 0);
    };
    btn.on('mousedown', () => {
      btn.filters = [dropShadow];
      btn.height = 49;
    });
    btn.on('mouseout', () => {
      btn.filters = [];
      btn.height = 50;
    });
    btn.on('touchstart', () => {
      btn.filters = [dropShadow];
      btn.height = 49;
    });
    btn.on('touchendoutside', () => {
      btn.filters = [];
      btn.height = 50;
    });
    btn.once('click', clickCallback);
    btn.once('touchend', clickCallback);

    return btn;
  };

  private initSoundSwitchBtn = (): Switch => {
    const btn = new Switch({
      label: i18n.t<string>('sound'),
      labelColor: SWITCH_LABELCOLOR,
    });
    btn.y = this.okBtn.height + GAP_Y;
    btn.setActive(setIsSoundOn());
    btn.interactive = true;
    btn.on('pointerdown', () => {
      const nextState = !setIsSoundOn();
      setIsSoundOn(nextState);
      AudioApi.setSoundState(nextState);
      setSoundValue(nextState ? 1 : 0);
    });
    return btn;
  };

  private initSkipIntroSwitchBtn = (): Switch => {
    const btn = new Switch({
      label: i18n.t<string>('show'),
      labelColor: SWITCH_LABELCOLOR,
    });
    btn.y = this.okBtn.height + GAP_Y;
    btn.x = this.soundSwitch.width + GAP_X;
    btn.interactive = true;
    btn.on('pointerdown', () => {
      setSkipIntroScreen(!setSkipIntroScreen());
    });

    return btn;
  };

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private setBgSize = (width: number, height: number): void => {
    this.backgroundContainer.x = width / 2;
    this.backgroundContainer.y = height / 2;

    const bgAspectRatio = this.background.width / this.background.height;
    const aspectRatio = width / height;
    if (bgAspectRatio > aspectRatio) {
      this.backgroundContainer.scale.set(height / this.background.height);
    } else {
      this.backgroundContainer.scale.set(width / this.background.width);
    }
  };

  private setControlsPositions = (width: number, _height: number): void => {
    this.okBtn.x = (this.controlsContainer.width - this.okBtn.width) / 2;
    this.controlsContainer.y = this.carousel.height + GAP_Y;
    this.controlsContainer.x = width / 2 - this.controlsContainer.width / 2;
  };

  private resize(width: number, height: number): void {
    this.setBgSize(width, height);
    this.carousel.setSize(width, height, this.controlsContainer.height + GAP_Y * 3);
    this.setControlsPositions(width, height);
    this.container.y = height / 2 - this.container.height / 2;
  }

  private destroy(): void {
    this.container.destroy();
    this.backgroundContainer.destroy();
    eventManager.removeListener(EventTypes.RESIZE, this.bindedResize);
  }
}

export default IntroScreen;
