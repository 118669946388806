import { RemoteStorage } from '@phoenix7dev/setting-store-client';

import type { SlotId } from './config';
import type { ReelSetType } from './gql/query';
import type Animation from './slotMachine/animations/animation';
import type Tween from './slotMachine/animations/tween';
import type { BgSkin } from './slotMachine/background/background';
import type { SlotMachineState } from './slotMachine/config';
import type { IWinLine, Icon, LineSet } from './slotMachine/d';
import { MeterKind, MeterLevel } from './slotMachine/meter/config';

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    PIXI: PIXI;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export interface IBalance {
  amount: number;
  currency: string;
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: IBalance;
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
  coinCode?: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export enum ETypeSP {
  FIXED = 'FIXED',
  MULTIPLIER = 'MULTIPLIER',
  REGULAR = 'REGULAR',
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    // slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    // userBonusId: string;
    userBonus: {
      betId: string;
      //   id: string;
      //   bonusId: string;
      //   lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      features: {
        gameRoundStore: {
          type: ETypeSP;
          meter: number;
          bonusId: string;
          countFS: number;
          multiplier: number;
          id: string;
          playedFS: number;
          heart?: number;
          maxMult?: number;
          spWin?: number;
        };
      };
      //   isBonus: boolean;
      //   bonuses: UserBonus[];
      //   features: {
      //     isFreeSpins: boolean;
      //   };
    };
    // createdAt: string;
    // updatedAt: string;
  };
  paylines: IWinLine[];
  balance: {
    placed: IBalance;
    settled: IBalance;
  };
  rewards: BetReward[];
}
export interface ISettledBet2 {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      features: {
        gameRoundStore: {
          type: ETypeSP;
          meter: number;
          bonusId: string;
          countFS: number;
          multiplier: number;
          id: string;
          playedFS: number;
          heart?: number;
          maxMult?: number;
          spWin?: number;
        };
      };
    };
  };
  paylines: IWinLine[];
  balance: {
    placed: IBalance;
    settled: IBalance;
  };
  rewards: BetReward[];
}
export type BetRewardType = 'BetBonusReward' | 'BetCoinReward' | 'ReplayBonusReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};

export type BonusType = 'FREE_SPIN' | 'SPECIAL_ROUND' | 'PRE_LOADED' | 'GAME_MODE' | 'FREE_BET_ROUND' | '';
export type BetReward = BetBonusReward | BetCoinReward;
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
};
export type BonusRoundData = {
  remainingRounds: number;
  landingDiamonds: { position: number; score: number }[];
};
export type BonusData = {
  legend: BonusRoundData[];
  multipliers: {
    rows: [number, number, number];
    columns: [number, number, number, number, number];
  };
  rewardAmount: number;
};
export type UserBonus = {
  betId: string;
  coinValue: number;
  bonusId: string;
  id: string;
  isActive: boolean;
  rounds: number;
  roundsPlayed: number;
  currentRound: number;
  totalRound: number;
  reelSetId: string;
  bonus: Bonus;
  coinAmount: number;
  totalWinAmount: number;
  status: BonusStatus;
  coinValue: number;
  data: UserBonusData;
  bonus?: {
    id: string;
    rounds: number;
    reelSetId: number;
  };
};
export type Bonus = {
  coinAmount: number;
  id: string;
  reelSetId: string;
  type: BonusType;
  slotId: string;
  title?: string;
  description?: string;
  lineSetId: string | null;
  purchasable: boolean;
  rounds: number;
  data: unknown;
};

export type UserBonusData = {
  stage: Stage[];
  count: number;
  creditMultiplier: number;
  targetCharacter: number;
  preLoadedGrantBreakdown: PreLoadedGrantBreakdown[];
  frbReferenceId: string | null;
};

export type Stage = {
  0: string;
  1: string;
  2: string;
};

enum ETypeSP {
  FIXED = 'FIXED',
  MULTIPLIER = 'MULTIPLIER',
  REGULAR = 'REGULAR',
}

export type ISPSymbol = {
  type: ETypeSP;
  meter: number;
  bonusId: string;
  countFS: number;
  multiplier: number;
  id: string;
  playedFS: number;
  heart?: number;
  maxMult?: number;
};

export enum GameMode {
  REGULAR,
  FREE_SPINS, // TODO 暫定
  FREE_SPIN_B,
  FREE_SPIN_C,
  FREE_SPIN_D,
  FREE_SPIN_E,
  FREE_SPIN_F,
  FREE_SPIN_G,
  FREE_SPIN_H,
  FREE_SPIN_I,
  FREE_SPIN_J,
  BUY_FEATURE,
}

export enum ReelId {
  REGULAR = '243677a9-3a9a-4045-9a43-b6864e2dbd1e',
  FREE_SPINS = '1d736c67-76c8-41dd-a34f-2ceef5cc0164',
  FREE_SPIN_B = '1d736c67-76c8-41dd-a34f-2ceef5cc0164',
  FREE_SPIN_C = 'fdc2b875-eaab-4262-b08a-f4cb26d8c5a0',
  FREE_SPIN_D = '49514645-c286-4e67-911c-8f90e5719103',
  FREE_SPIN_E = 'caa8434c-2bc7-4c66-a5ac-7af60fd39f03',
  FREE_SPIN_F = 'e6c649b6-7f3a-4bcb-8eee-3dc5e6c3a809',
  FREE_SPIN_G = '006c2886-8d54-444b-9ccf-72721047feaf',
  FREE_SPIN_H = '8bb9f988-6e08-44c8-8012-3bd66161523b',
  FREE_SPIN_I = '92c15270-0011-4d75-af5f-fcf33a1ae2fa',
  FREE_SPIN_J = '6ec39c2e-1d24-455e-aa1d-413fe104e969',
  BUY_FEATURE = '25c20873-93f9-4db8-a499-35dc545a6f5e',
}

export const reelSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: ReelId.REGULAR,
  [GameMode.FREE_SPINS]: ReelId.FREE_SPINS,
  [GameMode.FREE_SPIN_B]: ReelId.FREE_SPIN_B,
  [GameMode.FREE_SPIN_C]: ReelId.FREE_SPIN_C,
  [GameMode.FREE_SPIN_D]: ReelId.FREE_SPIN_D,
  [GameMode.FREE_SPIN_E]: ReelId.FREE_SPIN_E,
  [GameMode.FREE_SPIN_F]: ReelId.FREE_SPIN_F,
  [GameMode.FREE_SPIN_G]: ReelId.FREE_SPIN_G,
  [GameMode.FREE_SPIN_H]: ReelId.FREE_SPIN_H,
  [GameMode.FREE_SPIN_I]: ReelId.FREE_SPIN_I,
  [GameMode.FREE_SPIN_J]: ReelId.FREE_SPIN_J,
  [GameMode.BUY_FEATURE]: ReelId.BUY_FEATURE,
};

export const bonusIds: Record<GameMode, string> = {
  [GameMode.FREE_SPINS]: '8e85891f-e8f9-47d9-ac25-f2e5e31c16c1',
  [GameMode.FREE_SPIN_B]: '8e85891f-e8f9-47d9-ac25-f2e5e31c16c1',
  [GameMode.FREE_SPIN_C]: '5afb06d8-e7ef-4c1b-8e65-19c55e9806e2',
  [GameMode.FREE_SPIN_D]: '3da0d5a9-2377-464d-bbff-686a58898e22',
  [GameMode.FREE_SPIN_E]: '839888ef-1bd4-4ca0-9d3e-96850b8b6446',
  [GameMode.FREE_SPIN_F]: '0020c134-51ab-4f8c-bdb4-a00b62bd524a',
  [GameMode.FREE_SPIN_G]: 'dcdf0902-a475-4785-9a37-172e3851c1d3',
  [GameMode.FREE_SPIN_H]: 'fae20903-765d-4b07-b63f-e9cf1b9a61dc',
  [GameMode.FREE_SPIN_I]: '48732d0b-134c-4186-8708-8c73d0cfbb57',
  [GameMode.FREE_SPIN_J]: 'a48c8d02-452d-4797-8f3d-c0e2d59318e8',
  [GameMode.BUY_FEATURE]: '51f10918-5b24-4341-8560-fbea0b1c527d',
};

export const freeRoundBonusId = '7114f999-9d9d-4616-9a7c-03d6db1f9dbb';

export const lineSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '64ec94ee-e1b1-4753-b6eb-9b5f37f2961f',
  [GameMode.FREE_SPINS]: '64ec94ee-e1b1-4753-b6eb-9b5f37f2961f', // TODO 以下不要なら削除
  [GameMode.FREE_SPIN_B]: '64ec94ee-e1b1-4753-b6eb-9b5f37f2961f',
  [GameMode.FREE_SPIN_C]: '64ec94ee-e1b1-4753-b6eb-9b5f37f2961f',
  [GameMode.FREE_SPIN_D]: '64ec94ee-e1b1-4753-b6eb-9b5f37f2961f',
  [GameMode.FREE_SPIN_E]: '64ec94ee-e1b1-4753-b6eb-9b5f37f2961f',
  [GameMode.FREE_SPIN_F]: '64ec94ee-e1b1-4753-b6eb-9b5f37f2961f',
  [GameMode.FREE_SPIN_G]: '64ec94ee-e1b1-4753-b6eb-9b5f37f2961f',
  [GameMode.FREE_SPIN_H]: '64ec94ee-e1b1-4753-b6eb-9b5f37f2961f',
  [GameMode.FREE_SPIN_I]: '64ec94ee-e1b1-4753-b6eb-9b5f37f2961f',
  [GameMode.FREE_SPIN_J]: '64ec94ee-e1b1-4753-b6eb-9b5f37f2961f',
  [GameMode.BUY_FEATURE]: '64ec94ee-e1b1-4753-b6eb-9b5f37f2961f',
};

export const bonusIdsStringToGameMode: Record<string, GameMode> = {
  '8e85891f-e8f9-47d9-ac25-f2e5e31c16c1': GameMode.FREE_SPIN_B,
  '5afb06d8-e7ef-4c1b-8e65-19c55e9806e2': GameMode.FREE_SPIN_C,
  '3da0d5a9-2377-464d-bbff-686a58898e22': GameMode.FREE_SPIN_D,
  '839888ef-1bd4-4ca0-9d3e-96850b8b6446': GameMode.FREE_SPIN_E,
  '0020c134-51ab-4f8c-bdb4-a00b62bd524a': GameMode.FREE_SPIN_F,
  'dcdf0902-a475-4785-9a37-172e3851c1d3': GameMode.FREE_SPIN_G,
  'fae20903-765d-4b07-b63f-e9cf1b9a61dc': GameMode.FREE_SPIN_H,
  '48732d0b-134c-4186-8708-8c73d0cfbb57': GameMode.FREE_SPIN_I,
  'a48c8d02-452d-4797-8f3d-c0e2d59318e8': GameMode.FREE_SPIN_J,
};

export const bonusIdToReelsId: Record<string, string> = {
  '8e85891f-e8f9-47d9-ac25-f2e5e31c16c1': '1d736c67-76c8-41dd-a34f-2ceef5cc0164',
  '5afb06d8-e7ef-4c1b-8e65-19c55e9806e2': 'fdc2b875-eaab-4262-b08a-f4cb26d8c5a0',
  '3da0d5a9-2377-464d-bbff-686a58898e22': '49514645-c286-4e67-911c-8f90e5719103',
  '839888ef-1bd4-4ca0-9d3e-96850b8b6446': 'caa8434c-2bc7-4c66-a5ac-7af60fd39f03',
  '0020c134-51ab-4f8c-bdb4-a00b62bd524a': 'e6c649b6-7f3a-4bcb-8eee-3dc5e6c3a809',
  'dcdf0902-a475-4785-9a37-172e3851c1d3': '006c2886-8d54-444b-9ccf-72721047feaf',
  'fae20903-765d-4b07-b63f-e9cf1b9a61dc': '8bb9f988-6e08-44c8-8012-3bd66161523b',
  '48732d0b-134c-4186-8708-8c73d0cfbb57': '92c15270-0011-4d75-af5f-fcf33a1ae2fa',
  'a48c8d02-452d-4797-8f3d-c0e2d59318e8': '6ec39c2e-1d24-455e-aa1d-413fe104e969',
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
}

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: (() => void) | undefined;
  onInitCallback?: () => void;
};

export type MessageWinBannerProps = BaseMessageBannerProps & {
  title: string;
  totalWin: string;
};

export type ReplayFreeSpinBets = {
  id: string;
  reelSetId: string;
};

export enum TotalWinBannerMode {
  NON,
  DISABLE,
  ENABLE,
}

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  ANTICIPATION_ANIMATIONS_START = 'anticipationAnimationsStart',
  ANTICIPATION_ANIMATIONS_END = 'anticipationAnimationsEnd',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_START = 'countUpStart',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_WIN_LABEL = 'hideWinLabel',
  // HIDE_WIN_LINES = 'hideWinLines',
  HIDE_ALL_WIN_LINES = 'hideAllWinLines',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  SPACE_KEY_CLOSE_SP_SYMBOL_LOT = 'spaceKeyCloseSpSymbolLot',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_BACK_GROUND = 'ResizeBackGround',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_ANIMATOR = 'removeAnimator',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_SLOTS_STOP_DISPLAY_VISIBILITY = 'setSlotsStopDisplayVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SETUP_BET_RESULT = 'setupBetResult',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  REMOVE_ANTICIPATION_TINT = 'removeAnticipationTint', // TODO
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREE_SPINS_WIN = 'isFreeSpinWin',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_TOTAL_WIN_MESSAGE_BANNER = 'isTotalWinMessageBanner',
  SET_IS_COUNT_UP = 'setIsCountUp',
  SET_IS_IN_TRANSITION = 'isInTransition',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_UPDATE_FREE_SPINS_COUNT = 'handleUpdateFreeSpinsCount',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  SOUND_INITIALIZED = 'soundInitialized',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  SET_STATE = 'setState',
  SLOT_STOP_DISPLAY_HIDE = 'SlotStopDisplayHide',
  SET_SLOT_BUSY_DISABLE = 'SetSlotBusyDisable',
  PHOENIX_START = 'PhoenixStart',
  UI_VISIBLE = 'UiVisible',
  SET_BACKGROUND_PIVOT_Y = 'SetBackGroundPivotY',
  SPIN_END = 'SpinEnd',
  BONUS_END = 'BonusEnd',
  BONUS_WIN = 'BonusWin',
  TOGGLE_UI_MENU = 'ToggleUiMenu',
  WINDOW_NOT_ACTIVE = 'WindowNotActive',
  FREE_SPIN = 'FreeSpin',
  PLACE_BET_COMPLETED = 'PlaceBetCompleted',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  POPUP_BG_VISIBLE = 'popupBgVisible',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP = 'closeBuyFeatureConfirmPopup',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_IS_ACTIVE_FREE_SPINS_GAME = 'handleIsActiveFreeSpinsGame',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  INIT_METER_SET = 'initMeterSet',
  SET_METER = 'setMeter',
  SET_METER_MULTIPLIER = 'setMeterMultiplier',
  HEART_SET_ADD = 'heartSet',
  HEART_SHOT = 'heartShot',
  SCENE_CHANGE_START = 'SceneChangeStart',
  SP_SYMBOL_LOT_START = 'SpSymbolLotStart',
  SP_SYMBOL_IF_VISIBLE = 'SpSymbolIfVisible',
  SPACE_KEY_CLOSE_SP_SYMBOL_LOT = 'SPACE_KEY_CLOSE_SP_SYMBOL_LOT',
  FREE_SPINS_DISPLAY_FLASH = 'FreeSpinsDisplayFlash',
  FREE_SPINS_DISPLAY_SHOW = 'FreeSpinsDisplayShow',
  SET_SP_SYMBOL = 'SetSpSymbol',
  SCATTER_HEART_STOCK = 'ScatterHeartStock',
  EXPAND_HEART_STOCK = 'ExpandHeartStock',
  START_METER_ADD_COUNT = 'startMeterAddCount',
  START_EXPAND_SYMBOL = 'startExpandSymbol',
  END_EXPAND_SYMBOL = 'endExpandSymbol',
  SET_INIT_SCATTER_METER = 'setInitScatterMeter',
  METER_ADD_HEART = 'meterAddHeart',
  START_EXPAND_SCENE = 'startExpandScene',
  SCATTER_WIN_END = 'scatterWinEnd',
  SET_REEL_FRAME_VISIBLE = 'SetReelFrameVisible',
  START_GET_AMOUNT_WIN = 'StartGetAmountWin',
  SET_SCENE_CHANGE = 'SetSceneChange',
  SET_BROKEN_BUY_FEATURE = 'setBrokenFeature',
  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  SET_REPLAY_BET_ID = 'setReplayBetId',
  START_FREE_ROUND_BONUS = 'startFreeRoundBonus',
  END_FREE_ROUND_BONUS = 'endFreeRoundBonus',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  FREE_ROUND_BONUS_EXPIRED = 'freeRoundBonusExpired',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',

  PRESS_START_VISIBLE = 'pressStartVisible',
  BOOK_INFO_TEXT_VISIBLE = 'bookInfoTextVisible',

  LOT_CONFIRMED = 'lotConfirmed',
  TOTAL_WIN_DISPLAY_AVATAR = 'totalWinDisplayAvatar',

  START_FADE_TRANSITION_FREE_ROUNDS_BONUS = 'startFadeTransitionFreeRoundBonus',
  SET_IS_FREE_ROUND_AMOUNT_VISIBLE = 'setIsFreeRoundAmountVisible',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export interface Events {
  [EventTypes.ANTICIPATION_STARTS]: (index: number) => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_START]: () => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_END]: (reel?: number) => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_START]: (mode: WinStages, isExpand: boolean, winAmount: number) => void;
  [EventTypes.COUNT_UP_END]: (isExpand: boolean, value?: number) => void;
  [EventTypes.CHANGE_MODE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    background?: BgSkin;
    isRetrigger?: boolean;
  }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (props: MessageWinBannerProps) => void;
  [EventTypes.DISABLE_ALL_MINI_PAY_TABLES]: () => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HANDLE_CHANGE_RESTRICTION]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  // [EventTypes.HIDE_WIN_LINES]: (line: IWinLine[]) => void;
  [EventTypes.HANDLE_CHANGE_BET_AMOUNT]: (betAmount: number) => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: { mode: GameMode; background?: BgSkin }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_BACK_GROUND]: (scale: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.REMOVE_ANIMATOR]: (animator: () => void) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.SET_SLOTS_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_WIN_ANIMATION]: (
    nextResult: ISettledBet,
    isTurboSpin: boolean,
    isScatter: boolean,
    isExpand: boolean,
  ) => void;
  [EventTypes.START_SPIN_ANIMATION]: () => void;
  [EventTypes.START_MODE_CHANGE_FADE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
    background?: BgSkin;
  }) => void;
  //[EventTypes.SHOW_WIN_LINES]: (lines: IWinLine[]) => void;
  [EventTypes.HIDE_ALL_WIN_LINES]: () => void;
  // TODO [EventTypes.SHOW_WIN_LABEL]: () => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (spinResult: Icon[], constructorFlg?: boolean, reelId?: number) => void;
  // [EventTypes.SHOW_TINT]: (state: boolean, reelId?: number) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: number[],
    scatterNo: number[],
    expandNo: number[],
    expandSlotId: SlotId,
    anticipationStartReelId: number,
  ) => void;
  [EventTypes.SETUP_BET_RESULT]: (nextResult: ISettledBet) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.REMOVE_ANTICIPATION_TINT]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREE_SPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (isOpenedMessageBanner: boolean) => void;
  [EventTypes.SET_IS_TOTAL_WIN_MESSAGE_BANNER]: (isOpenedMessageBanner: setIsTotalWinMessageBanner) => void;
  [EventTypes.SET_IS_COUNT_UP]: (isCountUp: boolean) => void;
  [EventTypes.SET_IS_IN_TRANSITION]: (isInTransition: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT]: (spins: number, curr: number, immediately: boolean) => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.SOUND_INITIALIZED]: (isError: boolean) => void;
  [EventTypes.ENABLE_SOUND_LOADER]: () => void;
  [EventTypes.SET_STATE]: (state: SlotMachineState) => void;
  [EventTypes.SLOT_STOP_DISPLAY_HIDE]: (slots: number) => void;
  [EventTypes.SET_SLOT_BUSY_DISABLE]: () => void;
  [EventTypes.PHOENIX_START]: () => void;
  [EventTypes.UI_VISIBLE]: () => void;
  [EventTypes.SET_BACKGROUND_PIVOT_Y]: (pivotY: number) => void;
  [EventTypes.SPIN_END]: () => void;
  [EventTypes.BONUS_END]: () => void;
  [EventTypes.BONUS_WIN]: () => void;
  [EventTypes.TOGGLE_UI_MENU]: () => void;
  [EventTypes.WINDOW_NOT_ACTIVE]: () => void;
  [EventTypes.FREE_SPIN]: (isTurboSpin: boolean | undefined) => void;
  [EventTypes.PLACE_BET_COMPLETED]: () => void;
  [EventTypes.HANDLE_BUY_BONUS]: (bonusId: string) => void;
  [EventTypes.START_BUY_FEATURE_ROUND]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.POPUP_BG_VISIBLE]: (visible: boolean) => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (totalCost: string, coinAmount: number) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;

  [EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE]: (spins: string, curr: string, immediately?: boolean) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP]: () => void;
  [EventTypes.DISABLE_BUY_FEATURE_BTN]: (disable: boolean) => void;
  [EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME]: (isVisible: boolean) => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.INIT_METER_SET]: (setMultiPlierNum: number, setNum: MeterKind, isResume?: boolean) => void;
  [EventTypes.SET_METER]: (setMultiPlierNum: number, setNum: MeterLevel) => void;
  [EventTypes.SET_METER_MULTIPLIER]: (setMultiPlierNum: number) => void;
  [EventTypes.HEART_SET_ADD]: () => void;
  [EventTypes.HEART_SHOT]: () => void;
  [EventTypes.SCENE_CHANGE_START]: (isFreeSpins: boolean, reelPositions?: number[], reelSetId?: string) => void;
  [EventTypes.SP_SYMBOL_LOT_START]: () => void;
  [EventTypes.SP_SYMBOL_IF_VISIBLE]: () => void;
  [EventTypes.SPACE_KEY_CLOSE_SP_SYMBOL_LOT]: () => void;
  [EventTypes.FREE_SPINS_DISPLAY_FLASH]: () => void;
  [EventTypes.FREE_SPINS_DISPLAY_SHOW]: (visible: boolean) => void;
  [EventTypes.SET_SP_SYMBOL]: (resource: SlotId) => void;
  [EventTypes.SCATTER_HEART_STOCK]: (scatterCount: number[], scatterPosition: number[]) => void;
  [EventTypes.EXPAND_HEART_STOCK]: (expandCount: number[]) => void;
  [EventTypes.START_METER_ADD_COUNT]: () => void;
  [EventTypes.START_EXPAND_SYMBOL]: (
    expandPosition: number[],
    expandHeightPosition: number[],
    symbolKind: number,
    isMax: boolean,
  ) => void;
  [EventTypes.END_EXPAND_SYMBOL]: () => void;
  [EventTypes.SET_INIT_SCATTER_METER]: (scatterCount: number[], scatterPosition: number[]) => void;
  [EventTypes.METER_ADD_HEART]: (initScatterCount: number[], scatterCount: number[], expandCount: number[]) => void;
  [EventTypes.START_EXPAND_SCENE]: () => void;
  [EventTypes.SCATTER_WIN_END]: () => void;
  [EventTypes.SET_REEL_FRAME_VISIBLE]: (visible: boolean) => void;
  [EventTypes.START_GET_AMOUNT_WIN]: () => void;
  [EventTypes.SET_SCENE_CHANGE]: (isSceneChange: boolean) => void;
  [EventTypes.SET_BROKEN_BUY_FEATURE]: (boolean) => void;
  [EventTypes.FORCE_CLOSE_BUYFEATURE]: () => void;
  [EventTypes.SET_REPLAY_BET_ID]: () => void;
  [EventTypes.START_FREE_ROUND_BONUS]: () => void;
  [EventTypes.END_FREE_ROUND_BONUS]: () => void;
  [EventTypes.UPDATE_FREE_ROUNDS_LEFT]: (amount: number) => void;
  [EventTypes.FREE_ROUND_BONUS_EXPIRED]: () => void;
  [EventTypes.FORCE_STOP_AUTOPLAY]: () => void;
  [EventTypes.PRESS_START_VISIBLE]: (visible: boolean) => void;
  [EventTypes.BOOK_INFO_TEXT_VISIBLE]: (visible: boolean) => void;
  [EventTypes.LOT_CONFIRMED]: () => void;
  [EventTypes.TOTAL_WIN_DISPLAY_AVATAR]: () => void;
  [EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS]: (settings: {
    outDuration: number;
    inDuration: number;
    callback: () => void;
  }) => void;
  [EventTypes.SET_IS_FREE_ROUND_AMOUNT_VISIBLE]: (isFreeRound: boolean) => void;
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  ALBUM_MENU = 'ALBUM_MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
}

export enum BgmSoundTypes {
  BASE = 'regular',
  BB_Start_Before = 'bbStartBefore',
  BB = 'bb',
}

export enum ReelAnticipation {
  NON = 'NON',
  BONUS = 'BONUS',
}

export type StageData = {
  win: number;
  // inHoleType: HoleType;
};

export enum spSymbolReels {
  characterB = reelSets[GameMode.FREE_SPIN_B],
  characterC = reelSets[GameMode.FREE_SPIN_C],
  characterE = reelSets[GameMode.FREE_SPIN_D],
  characterE = reelSets[GameMode.FREE_SPIN_E],
  characterF = reelSets[GameMode.FREE_SPIN_F],
  characterG = reelSets[GameMode.FREE_SPIN_G],
  characterH = reelSets[GameMode.FREE_SPIN_H],
  characterI = reelSets[GameMode.FREE_SPIN_I],
  characterJ = reelSets[GameMode.FREE_SPIN_J],
}
