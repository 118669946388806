import { ITextStyle } from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const titleTextStyle: Partial<ITextStyle> = {
  fontSize: 100,
  fontFamily: font,
  whiteSpace: 'normal',
  align: 'center',
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#ffffff',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 16,
  dropShadowDistance: 4,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const descriptionTextStyle: Partial<ITextStyle> = {
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  fill: ['#9fffff', '#ffffff', '#3fbfff'],
  stroke: '#000000',
  strokeThickness: 2,
  dropShadow: true,
  dropShadowColor: 0x111122,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  align: 'center',
  fontVariant: 'normal',
  fontWeight: 'bolder',
  lineJoin: 'round',
  padding: 10,
};

export const bannerHeroTextStyle: Partial<ITextStyle> = {
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  fill: ['#b3ffff', '#0078ff', '#007dd4', '#20edff', '#2d28ff', '#0600ff', '#5b53ff', '#00065b', '#ffffff'],
  fillGradientStops: [0.2, 0.38, 0.5, 0.51, 0.53, 0.59, 0.66, 0.78, 0.8],
  stroke: '#ffffff',
  strokeThickness: 5,
  dropShadow: true,
  dropShadowColor: 0x111122,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  align: 'center',
  fontVariant: 'normal',
  fontWeight: 'bolder',
  lineJoin: 'round',
  padding: 10,
};

export const bannerRivalTextStyle: Partial<ITextStyle> = {
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  fill: ['#ffb3b3', '#ff0000', '#a30000', '#ffd738', '#ff3728', '#ff007e', '#ac008a', '#5b0038', '#ffffff'],
  fillGradientStops: [0.2, 0.38, 0.5, 0.51, 0.53, 0.59, 0.66, 0.78, 0.8],
  stroke: '#ffffff',
  strokeThickness: 5,
  dropShadow: true,
  dropShadowColor: 0x221111,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  align: 'center',
  fontVariant: 'normal',
  fontWeight: 'bolder',
  lineJoin: 'round',
  padding: 10,
};

export const btnTextStyle: Partial<ITextStyle> = {
  fontSize: 90,
  fontFamily: font,
  whiteSpace: 'normal',
  align: 'center',
  fill: ['#ffff9f', '#ffffff', '#ffff3f'],
  stroke: '#000000',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x111122,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const totalWinTitleStyles: Partial<ITextStyle> = {
  fontSize: 110,
  fontFamily: font,
  whiteSpace: 'normal',
  align: 'center',
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#ffffff',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 6,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const totalWinAmountTextStyles: Partial<ITextStyle> = {
  fontSize: 110,
  fontFamily: font,
  whiteSpace: 'normal',
  align: 'center',
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#ffffff',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 6,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const totalRoundAmountTextStyles: Partial<ITextStyle> = {
  fontSize: 70,
  fontFamily: font,
  whiteSpace: 'normal',
  align: 'center',
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#ffffff',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 6,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const bonusStreakTextStyle: Partial<ITextStyle> = {
  fontSize: 110,
  fontFamily: font,
  whiteSpace: 'normal',
  align: 'center',
  fill: ['#ffff9f', '#ffffff', '#ffff3f'],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  fontWeight: 'bolder',
  lineJoin: 'round',
};
