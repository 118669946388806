export const EXPAND_ANIMATIONS_NAME = 'symbol_expand';
export const EXPAND_ANIMATION_STEP = ['symbol_expand1', 'symbol_expand2', 'symbol_expand3'];

export const EXPAND_SYMBOL_ANIMATION_STEP = [
  'symbol_b3_win',
  'symbol_c3_win',
  'symbol_d3_win',
  'symbol_e3_win',
  'symbol_f3_win',
  'symbol_g3_win',
  'symbol_h3_win',
  'symbol_i3_win',
  'symbol_j3_win',
];

export const EXPAND_SYMBOL_ANIMATIONS = {
  symbol_b3_win: 0,
  symbol_c3_win: 1,
  symbol_d3_win: 2,
  symbol_e3_win: 3,
  symbol_f3_win: 4,
  symbol_g3_win: 5,
  symbol_h3_win: 6,
  symbol_i3_win: 7,
  symbol_j3_win: 8,
};

export const EXPAND_ANIMATION_DELAY = 1000;
export const EXPAND_SYMBOL_IN_ANIMATION_DELAY = 750;
export const EXPAND_SYMBOL_ANIMATION_DELAY = 750;
export const EXPAND_SYMBOL_ANIMATION_END_DELAY = 500;
