import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { BgSkin } from '../background/config';
import ViewContainer from '../components/container';
import { eventManager } from '../config';
import { MeterKind } from '../meter/config';

import { ARROW_ANIMATIONS, ARROW_ANIMATIONS_STEPS, ARROW_ANIMATION_NAME, ArrowPram } from './config';

class Arrow extends ViewContainer {
  private arrowAnimation: SpineAnimation | null = null;

  private heartCount = ARROW_ANIMATIONS.heart_stock0_idle;

  private meterKind!: MeterKind;

  constructor() {
    super();

    this.init();
  }

  private init(): void {
    this.containerInit();
    this.eventInit();
    this.animationInit();
  }

  private containerInit(): void {
    this.visible = false;
    this.position.set(ArrowPram.CONTAINER_LANDSCAPE_POS_X, ArrowPram.CONTAINER_LANDSCAPE_POS_Y);
  }

  private eventInit(): void {
    eventManager.addListener(EventTypes.INIT_METER_SET, this.initView.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeMode.bind(this));
    eventManager.addListener(EventTypes.HEART_SET_ADD, this.heartSet.bind(this));
    eventManager.addListener(EventTypes.HEART_SHOT, this.heartShot.bind(this));
    eventManager.addListener(EventTypes.FREE_SPINS_DISPLAY_SHOW, this.setDisplay.bind(this));
  }

  private initView(_setMultiPlierNum: number, setKind: MeterKind) {
    this.meterKind = setKind;
  }

  private animationInit(): void {
    this.arrowAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources[ARROW_ANIMATION_NAME]!.spineData);
    this.arrowAnimation.addOnStart(() => {
      this.addChild(this.arrowAnimation!.spine);
    });
  }

  private startAnimation(anim: string, isLoop: boolean): void {
    this.arrowAnimation!.setAnimation(anim, isLoop);
    this.arrowAnimation!.start();
  }

  private heartSet(): void {
    this.heartCount++;
    if (this.heartCount <= ARROW_ANIMATIONS.heart_stock6_idle) {
      this.startAnimation(ARROW_ANIMATIONS_STEPS[this.heartCount]!, true);
      if (this.heartCount === ARROW_ANIMATIONS.heart_stock6_idle) {
        this.heartCount = ARROW_ANIMATIONS.heart_stock0_idle;
      }
    } else {
      this.heartCount = ARROW_ANIMATIONS.heart_stock0_idle;
    }
  }

  private heartShot(): void {
    AudioApi.play({ type: ISongs.EffectMove });
    this.startAnimation(ARROW_ANIMATIONS_STEPS[ARROW_ANIMATIONS.heart_stock_stepup]!, false);
    this.heartCount = ARROW_ANIMATIONS.heart_stock0_idle;
  }

  private changeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    background?: BgSkin;
    isRetrigger?: boolean;
  }): void {
    if (settings.mode == GameMode.REGULAR) {
      this.visible = false;
      this.heartCount = ARROW_ANIMATIONS.heart_stock0_idle;
    } else {
      this.visible = false;
      this.heartCount = ARROW_ANIMATIONS.heart_stock0_idle;
    }
  }

  private setDisplay(visible: boolean): void {
    if (this.meterKind === MeterKind.SlotId_D_J || this.meterKind === MeterKind.SlotId_B_H_G) {
      const delay = Tween.createDelayAnimation(1);
      delay.addOnComplete(() => {
        this.visible = visible;
      });
      delay.start();
      this.heartCount = ARROW_ANIMATIONS.heart_stock0_idle;
      this.startAnimation(ARROW_ANIMATIONS_STEPS[ARROW_ANIMATIONS.heart_stock0_idle]!, true);
    } else {
      this.visible = false;
      this.heartCount = ARROW_ANIMATIONS.heart_stock0_idle;
    }
  }
}

export default Arrow;
