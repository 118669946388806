interface ArrowParameter {
  CONTAINER_PORTRAIT_POS_X: number;
  CONTAINER_PORTRAIT_POS_Y: number;
  CONTAINER_LANDSCAPE_POS_X: number;
  CONTAINER_LANDSCAPE_POS_Y: number;
}

export const ArrowPram: ArrowParameter = {
  CONTAINER_PORTRAIT_POS_X: 1270,
  CONTAINER_PORTRAIT_POS_Y: -75,
  CONTAINER_LANDSCAPE_POS_X: 1270,
  CONTAINER_LANDSCAPE_POS_Y: -75,
};

export const ARROW_ANIMATION_NAME = 'heart_stock';

export const ARROW_ANIMATIONS_STEPS = [
  'heart_stock0_idle',
  'heart_stock1_idle',
  'heart_stock2_idle',
  'heart_stock3_idle',
  'heart_stock4_idle',
  'heart_stock5_idle',
  'heart_stock6_idle',
  'heart_stock_stepup',
];

export const ARROW_ANIMATIONS = {
  heart_stock0_idle: 0,
  heart_stock1_idle: 1,
  heart_stock2_idle: 2,
  heart_stock3_idle: 3,
  heart_stock4_idle: 4,
  heart_stock5_idle: 5,
  heart_stock6_idle: 6,
  heart_stock_stepup: 7,
};

export const HEART_ACQUISITION_NAME = 'heart_acquisition';

export const SCATTER_HEART_STOCK_ANIMATIONS = [
  ['heart_acquisition_stepup_r1', 'heart_acquisition_stepup_r2', 'heart_acquisition_stepup_r3'],
  ['heart_acquisition_stepup_r4', 'heart_acquisition_stepup_r5', 'heart_acquisition_stepup_r6'],
  ['heart_acquisition_stepup_r7', 'heart_acquisition_stepup_r8', 'heart_acquisition_stepup_r9'],
  ['heart_acquisition_stepup_r10', 'heart_acquisition_stepup_r11', 'heart_acquisition_stepup_r12'],
  ['heart_acquisition_stepup_r13', 'heart_acquisition_stepup_r14', 'heart_acquisition_stepup_r15'],
];

export const EXPAND_HEART_STOCK_ANIMATIONS = [
  ['heart_acquisition_stepup_m1', 'heart_acquisition_stepup_m2', 'heart_acquisition_stepup_m3'],
  ['heart_acquisition_stepup_m1', 'heart_acquisition_stepup_m2', 'heart_acquisition_stepup_m3'],
  ['heart_acquisition_stepup_m1', 'heart_acquisition_stepup_m2', 'heart_acquisition_stepup_m3'],
  ['heart_acquisition_stepup_m1', 'heart_acquisition_stepup_m2', 'heart_acquisition_stepup_m3'],
  ['heart_acquisition_stepup_m4', 'heart_acquisition_stepup_m5', 'heart_acquisition_stepup_m6'],
];

export const HEART_STOCK_TO_METER_ANIMATIONS = 'heart_acquisition_stepup_c1';

export const FREE_SPIN_IN_SCATTER_HEART_STOCK_ANIMATION_DELAY = 1010;
export const SCATTER_HEART_STOCK_ANIMATION_DELAY = 900;

export const HEART_SHOT_BEFORE_DELAY = 500;
export const HEART_SHOT_ANIMATION_DELAY = 1010;

export const IN_EXPAND_DELAY = 500;
