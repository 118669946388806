import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '../../config';
import { IUserBalance } from '../../global';
import { setCoinValue } from '../../gql/cache';
import { getBetAmountGql, getUserGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { PAY_LINES } from '../../slotMachine/config';
import { countCoins, formatNumber, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import { IPaytableData } from './d';
import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number, slug: ResourceTypes): number => {
  if (slug === ResourceTypes.symbolANormal)
    return countCoins({ totalAmount: betAmount, coinValue: setCoinValue() }) * multiplier;
  return countCoins({ totalAmount: betAmount / PAY_LINES, coinValue: setCoinValue() }) * multiplier;
};

const hasTranslate = (slug: ResourceTypes): boolean => {
  return slug === ResourceTypes.symbolANormal;
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const {
    user: { balance },
  } = userData!;

  const { betAmount } = dataBet!;

  const data: IPaytableData[] = config.payTableData;
  const data2: IPaytableData[] = config.scatterTableData;
  const expandData: IPaytableData[] = config.ExpandTableData;

  const { currency } = balance;

  return (
    <>
      <div className={styles['paytable-new-line50px']} />
      <div className={styles['paytable-list-scatter']}>
        {data2.map(({ combos, slug }, index) => (
          <div key={index} className={styles['paytable-list-scatter__item']}>
            {hasTranslate(slug) && (
              <div key={slug} className={styles['paytable-list-scatter__item']}>
                <div className={styles['img']}>
                  <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                  <div className={styles['paytable-list-scatter__title']}>{t(`infoPayTable_${slug}`)}</div>
                </div>
                <div className={styles['content']}>
                  {combos.map((i) => (
                    <div key={i.pattern}>
                      <span className={styles['multiplier']}>{i.pattern} </span>
                      {formatNumber(currency, calcMultiplier(i.multiplier, betAmount, slug), showCurrency(currency))}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <section>
        <p
          className={`${styles['p']!} ${styles['center']!}`}
          dangerouslySetInnerHTML={{ __html: t('infoPayTable_symbolANormalInfo') }}
        />
      </section>
      <div className={styles['paytable-new-line50px']} />
      <div className={styles['paytable-list']}>
        {data.map(({ combos, slug }, index) => (
          <div key={index} className={styles['paytable-list__item']}>
            <div key={slug} className={styles['paytable-list__item']}>
              <div className={styles['img']}>
                <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                <div className={styles['paytable-list__title']}>{t(`infoPayTable_${slug}`)}</div>
              </div>
              <div className={styles['content']}>
                {combos.map((i) => (
                  <div key={i.pattern}>
                    <span className={styles['multiplier']}>{i.pattern} </span>
                    {formatNumber(currency, calcMultiplier(i.multiplier, betAmount, slug), showCurrency(currency))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <section>
        <h1 className={styles['title']}>{t('SpecialExpandingSymbolsTitle')}</h1>
        <p
          className={`${styles['p']!} ${styles['center']!}`}
          dangerouslySetInnerHTML={{ __html: t('SpecialExpandingSymbolsText1') }}
        />
      </section>
      <div className={styles['paytable-list-expand']}>
        {expandData.map(({ combos, slug }, index) => (
          <div key={index} className={styles['paytable-list-expand__item']}>
            <div key={slug} className={styles['paytable-list-expand__item']}>
              <div className={styles['expandimg']}>
                <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                <div className={styles['paytable-list-expand__title']}>{t(`infoPayTable_${slug}`)}</div>
              </div>
              <div className={styles['expandContent']}>
                {combos.map((i) => (
                  <div key={i.pattern}>
                    <span className={styles['multiplier']}>{i.pattern} </span>
                    {formatNumber(currency, calcMultiplier(i.multiplier, betAmount, slug), showCurrency(currency))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PaytableComponent;
