import { gql } from '@apollo/client';

export const BET = gql`
  fragment bet on Bet {
    id
    coinAmount
    coinValue
    slotId
    status
    userBonusId
    reelSetId
    data
    createdAt
    updatedAt
  }
`;

export const REEL_SET = gql`
  fragment reelSet on ReelSet {
    id
    layout
    cols
    rows
    additionalReelSets
    type
  }
`;

export const getBetSettingsGql = gql`
  fragment settings on Slot {
    clientSettings {
      coinAmounts {
        default
        quick
      }
      coinValues {
        code
        variants
      }
      features {
        id
        enabled
      }
    }
    settings {
      startPosition
    }
    lines
    lineSets {
      id
      slotId
      lines
      coinAmountMultiplier
    }
    reels {
      id
      type
      layout
    }
    icons {
      id
      type
      combos {
        type
        pattern
        rewards {
          type
          multiplier
          count
          bonusId
        }
        payoffType
      }
    }
  }
`;
