import { ITextStyle } from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const LAYOUT_OPTIONS = {
  width: 430,
  height: 295,
  border: 2,
  borderRadius: 5,
  margin: 20,
  padding: 20,
  borderColor: 0xf6e87e,
  buttonColor: 0xffffff,
  bgColor: 0x000000,
  traceIdOpacity: 0.3,
};

export const freeRoundsTextStyles: Partial<ITextStyle> = {
  align: 'center',
  fontFamily: font,
  fontSize: 20,
  fontWeight: 'bold',
  fill: '#ffffff',
};

export const buttonTextStyle: Partial<ITextStyle> = {
  align: 'center',
  fontFamily: font,
  fontSize: 20,
  fontWeight: 'bold',
};
