import { ResourceTypes } from '../../resources.d';
import { GAME_CONTAINER_WIDTH, SLOT_WIDTH } from '../config';

export const BANNER_POSITION_X = GAME_CONTAINER_WIDTH / 2;
export const BANNER_POSITION_Y = 10;

// WIN BANNER
export const MESSAGE_BANNER_POS_X = (SLOT_WIDTH * 5) / 2;
export const MESSAGE_BANNER_POS_Y = -50;
export const MESSAGE_WIN_BANNER_TITLE_Y = 200;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_Y = 450;
export const LEFT_RIGHT_OFFSET = 250;
export const MESSAGE_TOTAL_ROUND_BANNER_WIN_AMOUNT_Y = 610;

//delay
export const MESSAGE_BANNER_FLASH_DELAY = 1000;
export const MESSAGE_BANNER_LEVEL_UP_CHARACTER = 15;
export const MESSAGE_BANNER_LEVEL_UP_CLOSENESS = 1000;

export const MESSAGE_BANNER_FADE_IN_X = 500;
export const MESSAGE_BANNER_FADE_IN_ALPHA = 350;

export const CLOSENESS_LEVEL_X: number[] = [-200, -100, 0, 100, 200];
export const CLOSENESS_SCALE: number[] = [1, 0.7, 1, 0.7, 1];
export enum TOTAL_WIN_Z_INDEX {
  rect = 1,
  banner = 2,
  title = 3,
  totalWin = 4,
  totalRound = 5,
  closenessLevel = 6,
  flash = 7,
}

export const CLOSENESS_LEVEL_COLOR: ResourceTypes[] = [
  ResourceTypes.closenessLevelColor1,
  ResourceTypes.closenessLevelColor2,
  ResourceTypes.closenessLevelColor3,
  ResourceTypes.closenessLevelColor4,
  ResourceTypes.closenessLevelColor5,
];

export interface resourceInfo {
  resource: ResourceTypes;
  isLeft: boolean;
}

export const SymbolB: resourceInfo[] = [];
SymbolB[0] = { resource: ResourceTypes.symbolBWin1, isLeft: true };
SymbolB[1] = { resource: ResourceTypes.symbolBWin2, isLeft: true };
SymbolB[2] = { resource: ResourceTypes.symbolBWin3, isLeft: false };

export const SymbolC: resourceInfo[] = [];
SymbolC[0] = { resource: ResourceTypes.symbolCWin1, isLeft: false };
SymbolC[1] = { resource: ResourceTypes.symbolCWin2, isLeft: true };
SymbolC[2] = { resource: ResourceTypes.symbolCWin3, isLeft: false };

export const SymbolD: resourceInfo[] = [];
SymbolD[0] = { resource: ResourceTypes.symbolDWin1, isLeft: true };
SymbolD[1] = { resource: ResourceTypes.symbolDWin2, isLeft: false };
SymbolD[2] = { resource: ResourceTypes.symbolDWin3, isLeft: true };

export const SymbolE: resourceInfo[] = [];
SymbolE[0] = { resource: ResourceTypes.symbolEWin1, isLeft: true };
SymbolE[1] = { resource: ResourceTypes.symbolEWin2, isLeft: true };
SymbolE[2] = { resource: ResourceTypes.symbolEWin3, isLeft: false };

export const SymbolF: resourceInfo[] = [];
SymbolF[0] = { resource: ResourceTypes.symbolFWin1, isLeft: true };
SymbolF[1] = { resource: ResourceTypes.symbolFWin2, isLeft: false };
SymbolF[2] = { resource: ResourceTypes.symbolFWin3, isLeft: true };

export const SymbolG: resourceInfo[] = [];
SymbolG[0] = { resource: ResourceTypes.symbolGWin1, isLeft: false };
SymbolG[1] = { resource: ResourceTypes.symbolGWin2, isLeft: false };
SymbolG[2] = { resource: ResourceTypes.symbolGWin3, isLeft: false };

export const SymbolH: resourceInfo[] = [];
SymbolH[0] = { resource: ResourceTypes.symbolHWin1, isLeft: false };
SymbolH[1] = { resource: ResourceTypes.symbolHWin2, isLeft: true };
SymbolH[2] = { resource: ResourceTypes.symbolHWin3, isLeft: false };

export const SymbolI: resourceInfo[] = [];
SymbolI[0] = { resource: ResourceTypes.symbolIWin1, isLeft: false };
SymbolI[1] = { resource: ResourceTypes.symbolIWin2, isLeft: false };
SymbolI[2] = { resource: ResourceTypes.symbolIWin3, isLeft: true };

export const SymbolJ: resourceInfo[] = [];
SymbolJ[0] = { resource: ResourceTypes.symbolJWin1, isLeft: true };
SymbolJ[1] = { resource: ResourceTypes.symbolJWin2, isLeft: true };
SymbolJ[2] = { resource: ResourceTypes.symbolJWin3, isLeft: false };
