import { ResourceTypes } from '../resources.d';

const AlbumCharacter = {
  B: ResourceTypes.symbolBNormal,
  C: ResourceTypes.symbolCNormal,
  D: ResourceTypes.symbolDNormal,
  E: ResourceTypes.symbolENormal,
  F: ResourceTypes.symbolFNormalSp,
  G: ResourceTypes.symbolGNormalSp,
  H: ResourceTypes.symbolHNormalSp,
  I: ResourceTypes.symbolINormalSp,
  J: ResourceTypes.symbolJNormalSp,
};

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      gameId: 'Game ID',
      date: 'date',
      // balanceStart: 'Start balance',
      bet: 'bet',
      win: 'win',
      // balanceEnd: 'End Balance',
      // detail: 'Detail',
    },
  },
  specialPayTable: [
    {
      slug: ResourceTypes.symbolANormal,
      awardText: 'infoABonus',
    },
  ],
  miniPayTableData: [
    {
      slug: ResourceTypes.symbolANormal,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolBNormal,
      combos: [
        { pattern: 'x5', multiplier: 2000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 50 },
        { pattern: 'x2', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolCNormal,
      combos: [
        { pattern: 'x5', multiplier: 1000 },
        { pattern: 'x4', multiplier: 200 },
        { pattern: 'x3', multiplier: 20 },
        { pattern: 'x2', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolDNormal,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolENormal,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolFNormal,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolGNormal,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolHNormal,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolINormal,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolJNormal,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
  ],
  scatterTableData: [
    {
      slug: ResourceTypes.symbolANormal,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
  ],
  payTableData: [
    {
      slug: ResourceTypes.symbolBNormal,
      combos: [
        { pattern: 'x5', multiplier: 2000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 50 },
        { pattern: 'x2', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolCNormal,
      combos: [
        { pattern: 'x5', multiplier: 1000 },
        { pattern: 'x4', multiplier: 200 },
        { pattern: 'x3', multiplier: 20 },
        { pattern: 'x2', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolDNormal,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolENormal,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolFNormal,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolGNormal,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolHNormal,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolINormal,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolJNormal,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
  ],
  ExpandTableData: [
    {
      slug: ResourceTypes.symbolBSpecial,
      combos: [
        { pattern: 'x5', multiplier: 2000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 50 },
        { pattern: 'x2', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolCSpecial,
      combos: [
        { pattern: 'x5', multiplier: 1000 },
        { pattern: 'x4', multiplier: 200 },
        { pattern: 'x3', multiplier: 20 },
        { pattern: 'x2', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolDSpecial,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolESpecial,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolFSpecial,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolGSpecial,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolHSpecial,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolISpecial,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolJSpecial,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
  ],
  albumChara: [
    {
      slug: AlbumCharacter.B,
    },
    {
      slug: AlbumCharacter.C,
    },
    {
      slug: AlbumCharacter.D,
    },
    {
      slug: AlbumCharacter.E,
    },
    {
      slug: AlbumCharacter.F,
    },
    {
      slug: AlbumCharacter.G,
    },
    {
      slug: AlbumCharacter.H,
    },
    {
      slug: AlbumCharacter.I,
    },
    {
      slug: AlbumCharacter.J,
    },
  ],
};

export enum WinCharacterImg {
  img1,
  img2,
  img3,
}

export const winCharacterTbl: Record<string, Record<WinCharacterImg, string>> = {
  [AlbumCharacter.B]: {
    [WinCharacterImg.img1]: ResourceTypes.symbolBWin1,
    [WinCharacterImg.img2]: ResourceTypes.symbolBWin2,
    [WinCharacterImg.img3]: ResourceTypes.symbolBWin3,
  },
  [AlbumCharacter.C]: {
    [WinCharacterImg.img1]: ResourceTypes.symbolCWin1,
    [WinCharacterImg.img2]: ResourceTypes.symbolCWin2,
    [WinCharacterImg.img3]: ResourceTypes.symbolCWin3,
  },
  [AlbumCharacter.D]: {
    [WinCharacterImg.img1]: ResourceTypes.symbolDWin1,
    [WinCharacterImg.img2]: ResourceTypes.symbolDWin2,
    [WinCharacterImg.img3]: ResourceTypes.symbolDWin3,
  },
  [AlbumCharacter.E]: {
    [WinCharacterImg.img1]: ResourceTypes.symbolEWin1,
    [WinCharacterImg.img2]: ResourceTypes.symbolEWin2,
    [WinCharacterImg.img3]: ResourceTypes.symbolEWin3,
  },
  [AlbumCharacter.F]: {
    [WinCharacterImg.img1]: ResourceTypes.symbolFWin1,
    [WinCharacterImg.img2]: ResourceTypes.symbolFWin2,
    [WinCharacterImg.img3]: ResourceTypes.symbolFWin3,
  },
  [AlbumCharacter.G]: {
    [WinCharacterImg.img1]: ResourceTypes.symbolGWin1,
    [WinCharacterImg.img2]: ResourceTypes.symbolGWin2,
    [WinCharacterImg.img3]: ResourceTypes.symbolGWin3,
  },
  [AlbumCharacter.H]: {
    [WinCharacterImg.img1]: ResourceTypes.symbolHWin1,
    [WinCharacterImg.img2]: ResourceTypes.symbolHWin2,
    [WinCharacterImg.img3]: ResourceTypes.symbolHWin3,
  },
  [AlbumCharacter.I]: {
    [WinCharacterImg.img1]: ResourceTypes.symbolIWin1,
    [WinCharacterImg.img2]: ResourceTypes.symbolIWin2,
    [WinCharacterImg.img3]: ResourceTypes.symbolIWin3,
  },
  [AlbumCharacter.J]: {
    [WinCharacterImg.img1]: ResourceTypes.symbolJWin1,
    [WinCharacterImg.img2]: ResourceTypes.symbolJWin2,
    [WinCharacterImg.img3]: ResourceTypes.symbolJWin3,
  },
};

export enum SlotId {
  WL = 'WL', // BOOK
  B = 'B', // High1
  C = 'C', // High2
  D = 'D', // High3
  E = 'E', // High4
  F = 'F', // Low1
  G = 'G', // Low2
  H = 'H', // Low3
  I = 'I', // Low4
  J = 'J', // Low5
}

export enum SlotIdH {
  H_1 = 'H_1',
  H_2 = 'H_2',
  H_3 = 'H_3',
  H_4 = 'H_4',
}

export enum SlotIdI {
  I_1 = 'I_1',
  I_2 = 'I_2',
  I_3 = 'I_3',
  I_4 = 'I_4',
}
export enum ReelStopSndType {
  Normal,
  BonusStop1,
  BonusStop2,
}

export enum EyeControl {
  IDLE = 'idle',
  PLAY = 'play',
}

export const SC_REELS = [0, 2, 4];
export const ANTICIPATION_START_REELID = 3;
export const ANTICIPATION_START_SCCNT = 2;

export const BB_BGM_2 = 6;

export const black = '\u001b[30m';
export const red = '\u001b[31m';
export const green = '\u001b[32m';
export const yellow = '\u001b[33m';
export const blue = '\u001b[34m';
export const magenta = '\u001b[35m';
export const cyan = '\u001b[36m';
export const white = '\u001b[37m';

export const reset = '\u001b[0m';
