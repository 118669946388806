import * as PIXI from 'pixi.js';

import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isMobileDevice, updateTextScale } from '../../utils';
import {
  ALBUM_BTN_TEXT_X_OFFSET,
  ALBUM_BTN_TEXT_Y_OFFSET,
  FEATURE_BTN_LANDSCAPE_HEIGHT,
  FEATURE_BTN_LANDSCAPE_WIDTH,
  albumTextStyle,
} from '../config';

class AlbumBtnIcon extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  isDisabled: boolean;

  constructor() {
    super();
    this.isDisabled = false;
    this.visible = true;
    this.btn = this.initAlbumBtn();
    this.addChild(this.btn);

    this.text = this.initAlbumText();
    this.addChild(this.text);
  }

  private initAlbumBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.albumBtn));
    btn.width = FEATURE_BTN_LANDSCAPE_WIDTH;
    btn.height = FEATURE_BTN_LANDSCAPE_HEIGHT;
    btn.x = 0;
    btn.y = 0;
    btn.interactive = true;
    return btn;
  }

  private initAlbumText(): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>('albumBtn'), albumTextStyle);
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    text.position.set(this.width / 2 + ALBUM_BTN_TEXT_X_OFFSET, this.height / 2 + ALBUM_BTN_TEXT_Y_OFFSET);
    updateTextScale(text, isMobileDevice() ? 120 : 80, isMobileDevice() ? 150 : 120);

    return text;
  }
}

export default AlbumBtnIcon;
