import type React from 'react';

import styles from './button.module.scss';
import type { IButtonIntents, PropsButton } from './d';

const buttonIntents: IButtonIntents = {
  button_ok: styles['button_ok'] as string,
  button_cancel: styles['button_cancel'] as string,
  close: styles['close_btn'] as string,
  betSettingsBtn: styles['betSettingsBtn'] as string,
  autoPlaySettingBtn: styles['autoPlaySettingBtn'] as string,
  info: `${styles['menuBtn']} ${styles['info']}`,
  settings: `${styles['menuBtn']} ${styles['settings']}`,
  help: `${styles['menuBtn']} ${styles['help']}`,
  history: `${styles['menuBtn']} ${styles['history']}`,
  lobby: `${styles['menuBtn']} ${styles['lobby']}`,
};

export const clickButton = (fnOnClick: () => void, disabled: boolean) => (): void | undefined => {
  if (!disabled) {
    return fnOnClick();
  }
  return undefined;
};

export const clickCaptureButton = (fnOnClick: (() => void) | undefined, disabled: boolean) => (): void | undefined => {
  if (!disabled && fnOnClick) {
    return fnOnClick();
  }
  return undefined;
};
export const touchStartButton = (fnOnClick: (() => void) | undefined, disabled: boolean) => (): void | undefined => {
  if (!disabled && fnOnClick) {
    return fnOnClick();
  }
  return undefined;
};
const getClassName = (intent: string, isActive: boolean) => {
  if (!intent) return '';

  return `${buttonIntents[intent as string]} ${isActive ? styles['active'] : ''}`;
};

const Button: React.FC<PropsButton> = (props) => {
  const {
    title = '',
    className = '',
    disabled = false,
    onTouchStart,
    onClickCapture,
    onClick,
    type,
    intent = '',
    isActive = false,
    children,
    ...otherProps
  } = props;
  return (
    <button
      className={`${styles['btn']} ${isActive ? styles['active'] : ''} ${getClassName(intent, isActive)} ${className}`}
      type={type || 'button'}
      onClick={clickButton(onClick, disabled)}
      onTouchStart={touchStartButton(onTouchStart, disabled)}
      onClickCapture={clickCaptureButton(onClickCapture, disabled)}
      disabled={disabled}
      {...otherProps}
    >
      {children || title}
    </button>
  );
};

export default Button;
