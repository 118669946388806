import * as PIXI from 'pixi.js';

import { BFTH_COLOR_INTRO } from '../../config';

export type KnobButtonStyle = {
  width: number;
  backgroundColor: string;
  backgroundActiveColor: string;
  borderSize: number;
  borderColor: string;
  knobBorderColor: string;
  knobColor: string;
};

export const defaultKnobStyle: KnobButtonStyle = {
  width: 40,
  backgroundColor: '#323232',
  backgroundActiveColor: BFTH_COLOR_INTRO,
  borderSize: 1,
  borderColor: '#959595',
  knobBorderColor: '#646464',
  knobColor: '#ffffff',
};

export class KnobButton extends PIXI.Sprite {
  protected active: boolean;

  private resolution: number;

  private dirty: boolean;

  private style: KnobButtonStyle;

  private padding: number;

  public canvas: HTMLCanvasElement | null;

  public context: CanvasRenderingContext2D | null;

  constructor(style?: Partial<KnobButtonStyle>, active = false) {
    const canvas = document.createElement('canvas');
    const texture = PIXI.Texture.from(canvas);
    super(texture);

    this.style = { ...defaultKnobStyle, ...style };
    this.padding = Math.ceil(this.style.borderSize / 2);
    texture.orig = new PIXI.Rectangle(
      0,
      0,
      this.style.width + this.padding * 2,
      this.style.width / 2 + this.padding * 2,
    );

    this.canvas = canvas;
    this.context = canvas.getContext('2d');
    this.active = active;
    this.resolution = PIXI.settings.RESOLUTION;

    this.dirty = true;
  }

  private updateTexture() {
    if (!this.dirty) return;

    const canvas = this.canvas!;
    const ctx = this.context!;

    const { width, backgroundColor, backgroundActiveColor, borderSize, borderColor, knobBorderColor, knobColor } =
      this.style;
    const height = width / 2;
    const radius = height / 2;

    canvas.width = (width + this.padding * 2) * this.resolution;
    canvas.height = (height + this.padding * 2) * this.resolution;

    ctx.scale(this.resolution, this.resolution);
    ctx.translate(this.padding, this.padding);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // drawRoundRect
    ctx.beginPath();
    ctx.moveTo(radius, 0);
    ctx.lineTo(width - radius, 0);
    ctx.arcTo(width, 0, width, radius, radius);
    ctx.arcTo(width, height, width - radius, height, radius);
    ctx.lineTo(radius, height);
    ctx.arcTo(0, height, 0, radius, radius);
    ctx.arcTo(0, 0, radius, 0, radius);
    ctx.closePath();
    ctx.fillStyle = this.active ? backgroundActiveColor : backgroundColor;
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = borderSize;
    ctx.fill();
    ctx.stroke();

    // drawKnob
    ctx.beginPath();
    ctx.arc(this.active ? width - radius : radius, radius, radius * 0.8, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fillStyle = knobColor;
    ctx.strokeStyle = knobBorderColor;
    ctx.lineWidth = 1;
    ctx.fill();
    ctx.stroke();

    this._onTextureUpdate();
    this.texture.baseTexture.setRealSize(canvas.width, canvas.height, this.resolution);
    this._recursivePostUpdateTransform();

    this.dirty = false;
  }

  protected override _render(renderer: PIXI.Renderer): void {
    if (this.resolution !== renderer.resolution) {
      this.resolution = renderer.resolution;
      this.dirty = true;
    }
    this.updateTexture();
    super._render(renderer);
  }

  public override destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    options = {
      texture: true,
      children: false,
      baseTexture: true,
      ...options,
    };

    super.destroy(options);

    this.canvas!.width = 0;
    this.canvas!.height = 0;

    this.context = null;
    this.canvas = null;
  }

  setActive(active: boolean) {
    if (this.active !== active) {
      this.dirty = true;
      this.active = active;
    }
  }
}
