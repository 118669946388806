import _ from 'lodash';

import { SlotId } from '../../config';
import { GameMode, freeRoundBonusId, reelSets } from '../../global.d';
import { setCoinAmount, setSlotConfig } from '../../gql/cache';
import { IAlbumInfo } from '../../gql/d';
import { BaseGameMotionType } from '../../slotMachine/avatarMotion/config';
import { REELS_AMOUNT } from '../../slotMachine/config';
import { Icon } from '../../slotMachine/d';
import { MeterKind } from '../../slotMachine/meter/config';
import { remoteStorage } from '../remoteStorage';

export const getIsHiPay = (slotId: SlotId): boolean => {
  if (slotId === SlotId.B || slotId === SlotId.C || slotId === SlotId.D || slotId === SlotId.E) {
    return true;
  } else {
    return false;
  }
};

export const getExpandCount = (spinResult: Icon[], expandId: SlotId): number[] => {
  let count = 0;
  return _(spinResult)
    .chunk(REELS_AMOUNT)
    .unzip()
    .map((col) => {
      if (col.some((icon) => icon.id === expandId)) {
        count += 1;
        return count;
      }
      return 0;
    })
    .value();
};

export const getAddHeartCount = (
  meterKind: MeterKind,
  nowMultiPlierNum: number,
  prevMultiPlierNum: number,
  nowHeartNum: number,
  prevHeartNum: number,
): number => {
  if (nowMultiPlierNum === prevMultiPlierNum && nowHeartNum === prevHeartNum) {
    return 0;
  }
  if (meterKind === MeterKind.SlotId_D_J) {
    let heartNum = 0;

    heartNum += (nowMultiPlierNum - prevMultiPlierNum) * 1;

    heartNum += nowHeartNum - prevHeartNum;

    return heartNum;
  } else if (meterKind === MeterKind.SlotId_B_H_G) {
    let heartNum = 0;

    heartNum += (nowMultiPlierNum - prevMultiPlierNum) * 3;

    heartNum += nowHeartNum - prevHeartNum;

    return heartNum;
  } else {
    return 0;
  }
};

export const getUserAlbumInfo = (name: string, value: number): number => {
  if (!remoteStorage.isReady) {
    return value;
  }
  const item = remoteStorage.getItem<IAlbumInfo>('albumInfo');
  if (item) {
    if (item[name as string] === undefined) {
      Object.assign(item, { [name]: value });
      remoteStorage.setItem('albumInfo', item);
    }
    return item[name as string] as number;
  }
  remoteStorage.setItem('albumInfo', { [name]: value });
  return value;
};

export const setUserAlbumInfo = (name: string, value: number): number => {
  if (!remoteStorage.isReady) {
    return value;
  }
  const item = remoteStorage.getItem<IAlbumInfo>('albumInfo');
  if (item) {
    value++;
    Object.assign(item, { [name]: value });
    remoteStorage.setItem('albumInfo', item);
  }
  return value;
};

export const isBaseGameMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR;
};
export const isFreeSpinsMode = (mode: GameMode): boolean => {
  return (
    mode === GameMode.FREE_SPINS ||
    mode === GameMode.FREE_SPIN_B ||
    mode === GameMode.FREE_SPIN_C ||
    mode === GameMode.FREE_SPIN_D ||
    mode === GameMode.FREE_SPIN_E ||
    mode === GameMode.FREE_SPIN_F ||
    mode === GameMode.FREE_SPIN_G ||
    mode === GameMode.FREE_SPIN_H ||
    mode === GameMode.FREE_SPIN_I ||
    mode === GameMode.FREE_SPIN_J
  );
};
export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE;
};

export const isFreeRoundBonus = (bonusId: string): boolean => {
  return bonusId === freeRoundBonusId;
};

export const isScatter = (slotId: SlotId): boolean => {
  return slotId === SlotId.WL;
};

export const getGameModeByReelSetId = (reelSetId: string): GameMode => {
  for (const [gameMode, id] of Object.entries(reelSets)) {
    if (id === reelSetId) {
      return Number(gameMode) as GameMode;
    }
  }

  return GameMode.REGULAR;
};

export const getWinCoin = (): number => {
  return setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier;
};

export const isMotionLoop = (motionType: BaseGameMotionType): boolean => {
  return motionType === BaseGameMotionType.BonusWin;
};
